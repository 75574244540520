import { FooterContainer } from 'components/footer/FooterContainer';
import Box from '@mui/material/Box';
import { ChecklistStorageType } from 'store/actions/types';
import { Button } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { openLetters } from 'store/ui/modals/letters';
import React, { useEffect } from 'react';
import { useAmendChecklistMutation } from 'graphql/hooks/amendChecklist';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import { ROUTE } from 'consts/ROUTE';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { MozartOutOfTurnSteps } from 'features/mozart/components';
import { useNavigate } from 'react-router';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';
import { useNewEpisodeCheck } from 'components/scenario/episode/hooks';
import { updateNonMozartDocumentState } from 'store/ui/print/documents';
import { PRINT_SOURCES } from 'backend/types/printSources';
import DocumentsPrintPreview from 'components/home/patientdetails/tabscontent/episodesTab/subTabs/DocumentsPrintPreview';
import { RoleType } from 'store/roles/types';

interface IFinishFooterProps {
  isAmendAvailable?: boolean;
  storageType: ChecklistStorageType;
  isLettersAvailable?: boolean;
  patientId?: string;
}

const StyledDivider = styled(Divider)`
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
  height: 100%;
`;

const FinishFooter = ({
  isAmendAvailable,
  storageType,
  isLettersAvailable,
  patientId,
}: IFinishFooterProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const episodeId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].episodeId
  );
  const isPrintOpen = useSelector(
    (state: IState) => state.ui.print.documents.isPrintOpen
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    amendChecklist,
    {
      isLoading: isAmendingLoading,
      isSuccess: isSuccessAmendDone,
      data: amendChecklistResult,
    },
  ] = useAmendChecklistMutation();

  const {
    checkNewEpisode,
    checkNewEpisodeIsFetching,
    confirmation: newEpisodeConfirmation,
  } = useNewEpisodeCheck();
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const sectionName = 'Episode Summary';
  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();

  useEffect(() => {
    if (isSuccessAmendDone) {
      navigate(`/Checklist/Actions/${amendChecklistResult?.amendChecklist}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAmendDone]);

  const onAmendChecklist = () => {
    if (isAmendAvailable) {
      amendChecklist({ checklistId: Number(checklistId) });
    }
  };

  const handleNewScenario = () => {
    handleDirtyConfirm(isDirty, onConfirmNewScenario);
  };

  const onConfirmNewScenario = () => {
    clearDirtyState();
    dispatch(setWorkflowStep({ step: 1, name: 'Scenario' }));
    checkNewEpisode(
      Number(patientId),
      roleType === RoleType.NONCLINICAL ? 0 : episodeId ?? 0
    );
  };

  const handleReturnHome = () => {
    handleDirtyConfirm(isDirty, onConfirmReturnHome);
  };

  const onConfirmReturnHome = () => {
    clearDirtyState();
    navigate(ROUTE.Home);
  };
  const handlePrint = () => {
    dispatch(
      updateNonMozartDocumentState({
        episodeId: episodeId ?? 0,
        selectedChecklistIds: [Number(checklistId)],
      })
    );
  };
  return (
    <FooterContainer
      data-testid="FinishFooter"
      loading={isAmendingLoading || checkNewEpisodeIsFetching}
    >
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={sectionName}
      />
      <Box>
        {isPrintOpen && (
          <DocumentsPrintPreview
            open={isPrintOpen}
            printSource={PRINT_SOURCES.DOCUMENT}
            patientId={Number(patientId)}
          />
        )}
        <Box>
          {storageType === ChecklistStorageType.MOZART ? (
            <MozartOutOfTurnSteps />
          ) : null}
          {isAmendAvailable && (
            <Button
              onClick={onAmendChecklist}
              color="primary"
              data-cy="back-to-scenario"
              size="large"
              startIcon={
                <Icon
                  icon={ICONS.Back}
                  size={22}
                  color={COLORS.SYMPHONY_BLUE}
                />
              }
            >
              Amend
            </Button>
          )}
        </Box>
        <Box display="flex">
          <Button
            onClick={() => handlePrint()}
            color="primary"
            data-cy="print"
            size="large"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            startIcon={
              <Icon icon={ICONS.Print} size={22} color={COLORS.SYMPHONY_BLUE} />
            }
            variant="outlined"
          >
            Print
          </Button>
          {isLettersAvailable && (
            <Button
              color="primary"
              data-cy="open-letters"
              size="large"
              style={{
                marginLeft: '12px',
                marginRight: '12px',
              }}
              startIcon={
                <Icon
                  icon={ICONS.Letter}
                  size={22}
                  color={COLORS.SYMPHONY_BLUE}
                />
              }
              variant="outlined"
              onClick={() =>
                dispatch(
                  openLetters({
                    episodeId: episodeId ?? 0,
                    patientId: Number(patientId ?? 0),
                  })
                )
              }
            >
              Letters
            </Button>
          )}
          <StyledDivider orientation="vertical" variant="middle" flexItem />
          <Button
            color="primary"
            size="large"
            onClick={handleNewScenario}
            data-cy="new-scenario"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            variant="outlined"
          >
            New Scenario
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginLeft: '12px' }}
            onClick={handleReturnHome}
            data-cy="return-home"
          >
            Return Home
          </Button>
          {newEpisodeConfirmation}
        </Box>
      </Box>
    </FooterContainer>
  );
};

export default FinishFooter;
