import { GetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import { BaseReminder, Patient, Tag } from 'graphql/graphqlTypes';
import Panel from 'components/panel';
import PatientCard from 'components/scenario/patient';
import React from 'react';
import { ChecklistStorageType } from 'store/actions/types';

interface IPatientCardWrapperProps {
  patientData: GetPatientCardInfoQuery | undefined;
  storageType: ChecklistStorageType;
  isOptimizedView?: boolean;
}

export const PatientCardWrapper = ({
  patientData,
  storageType,
  isOptimizedView = false,
}: IPatientCardWrapperProps) => (
  <Panel padding="2px 0 0">
    <PatientCard
      patient={patientData?.getPatientById as Patient}
      patientTopReminder={patientData?.getTopReminder as BaseReminder}
      variant="small"
      tags={patientData?.getPatientTags as Tag[]}
      storageType={storageType}
      episodeLabelHasBorder
      OutInPatientAuthorizations={patientData?.getPatientOpIpAuthorizations}
      isOptimizedView={isOptimizedView}
    />
  </Panel>
);
