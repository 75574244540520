import React from 'react';
import { Box, Typography } from '@mui/material';
import Info from './Info';
import PatientNotes from './PatientNotes';
import PatientActionButtons from './PatientActionButtons';
import { COLORS } from 'consts/styles';
import {
  BaseReminder,
  ChecklistCategory,
  ChecklistItemMedicalCodes,
  ChecklistItemOrderable,
  ChecklistViewModel,
  EntityAttribute,
  EntityAttributeComponent,
  Patient,
  Tag,
} from 'graphql/graphqlTypes';
import { useGetOpenedClinicalEpisodeQuery } from 'graphql/hooks/getOpenedClinicalEpisode';
import styled from 'styled-components';
import EpisodeId from 'components/scenario/patient/EpisodeId';
import PatientMainDetails from 'components/scenario/patient/PatientMainDetails';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import { ChecklistStorageType, IDiagnosisCodes } from 'store/actions/types';
import EpisodeDetails from './EpisodeDetails';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Privilege, RoleType } from 'store/roles/types';
import PatientOutpatientInpatient from './PatientOutpatientInpatient';
import { GetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';

interface IStyledButtonWrapperProps {
  $variant?: string;
  $isPrintingOpen?: boolean;
}

const StyledButtonsWrapper = styled.div<IStyledButtonWrapperProps>`
  padding: ${(props) =>
    props.$isPrintingOpen ? '5px 15px 6px' : '8px 24px 20px'};
  margin-top: ${(props) => (props.$variant === 'large' ? 'auto' : '0')};
  border-top: ${(props) =>
    props.$variant === 'large' ? `1px solid ${COLORS.GREY25}` : 'none'};
`;

export const StyledSummaryBox = styled(Box)({
  height: 'auto',
  maxHeight: '30vh',
  overflowY: 'auto',
});

export interface IPatientCardProps {
  patient: Patient;
  checklist?: ChecklistViewModel | null;
  patientTopReminder?: BaseReminder;
  tags: Tag[];
  hideEducate?: boolean;
  hideQuickNote?: boolean;
  attributes?: EntityAttribute[];
  variant: 'small' | 'large';
  episodeLabelHasBorder?: boolean;
  storageType: ChecklistStorageType;
  OutInPatientAuthorizations: GetPatientCardInfoQuery['getPatientOpIpAuthorizations'];
  isOptimizedView?: boolean;
}

const getServiceLocationFromChecklist = (categories: ChecklistCategory[]) => {
  return categories
    ?.flatMap((category) => category?.items as ChecklistItemOrderable[])
    .filter((x) => x.isVisible)
    .flatMap((categoryItem) => categoryItem?.lines)
    .filter((line) => line)
    .flatMap((item) => item?.components as EntityAttributeComponent[])
    .find(
      (field) =>
        field?.componentType === 'EntityAttributeComponent' &&
        field?.fieldName === 'Episode.ServiceLocation' &&
        field?.value
    )?.value;
};

const getDiagnosisCodeItems = (categories: ChecklistCategory[] | undefined) => {
  return categories
    ?.flatMap((category) => category?.items as ChecklistItemMedicalCodes[])
    .filter((categoryItem) => categoryItem?.type === 'medical codes');
};
const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledSmallWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
  flex-direction: column;
`;

const StyledPatientNotesWrapper = styled.div`
  padding: 16px 24px 0;
`;

const PatientCard = (props: IPatientCardProps) => {
  const {
    variant,
    patient,
    tags,
    attributes,
    patientTopReminder,
    episodeLabelHasBorder,
    hideQuickNote,
    storageType,
    OutInPatientAuthorizations,
    isOptimizedView = false,
  } = props;

  const completedSections = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const completedChecklists = Object.values(completedSections);

  completedChecklists.sort(function (a, b) {
    return Number(b.id) - Number(a.id);
  });

  const completedServiceLocationId = completedChecklists
    .flatMap((checklist) =>
      getServiceLocationFromChecklist(checklist?.categories)
    )
    .find((id) => !!id);

  const { checklist } = useChecklistForSidebar(storageType);

  const { data } = useGetOpenedClinicalEpisodeQuery(
    {
      patientId: patient?.id,
    },
    { skip: patient === undefined || checklist !== undefined }
  );

  const currentServiceLocationId = getServiceLocationFromChecklist(
    checklist?.categories
  );

  const getServiceLocationItems = () => {
    const lookups =
      storageType === 'mozart' &&
      completedServiceLocationId &&
      !currentServiceLocationId
        ? completedChecklists.flatMap((x) => x?.lookups)
        : checklist?.lookups;
    const serviceLocationId =
      storageType === 'mozart' &&
      completedServiceLocationId &&
      !currentServiceLocationId
        ? completedServiceLocationId
        : currentServiceLocationId;
    return {
      lookups: lookups,
      serviceLocationId: serviceLocationId,
    };
  };
  const serviceLocationItems = getServiceLocationItems();
  const lookups = serviceLocationItems.lookups;
  const serviceLocationId = serviceLocationItems.serviceLocationId;

  const serviceLocation = lookups
    ?.find((x) => x.name == 'EpisodeServiceLocation')
    ?.values?.find((value) => value.id.toString() == serviceLocationId)?.name;

  const recentChecklist = completedChecklists.find((x) =>
    x.categories?.find((c) =>
      c.items?.find((item) => item?.type === 'medical codes')
    )
  );
  const completedDxCodeItems = getDiagnosisCodeItems(
    recentChecklist?.categories
  );

  const currentDxCodeItems = getDiagnosisCodeItems(checklist?.categories);

  const diagCodes =
    storageType === 'mozart' &&
    completedDxCodeItems?.length &&
    !currentDxCodeItems?.length
      ? completedDxCodeItems
      : currentDxCodeItems;

  const diagnosisCodes: IDiagnosisCodes[] = (diagCodes ?? [])
    .filter(
      (diagCode) =>
        diagCode.type === 'medical codes' &&
        diagCode.options.type === 'ICD_10_CM'
    )
    .flatMap((diagCode) => diagCode.value ?? [])
    .filter((medCode) => medCode !== null)
    .map((medCode) => ({
      code: medCode?.code,
      description: medCode?.description,
    }));

  const infoBackgroundColor = data?.getOpenedClinicalEpisode?.id
    ? COLORS.GREY4
    : 'transparent';

  const getEntityAttribute = (reason: string) =>
    data?.getOpenedClinicalEpisode?.entityAttributes?.find(
      (item) => item?.name === reason
    )?.value ?? '';

  const renderEpisodeSummary = () => {
    const canAllowSeeEpisodeSummary = hasUserPrivilege(
      Privilege.AllowSeeEpisodeSummary
    );
    const episodeSummary = getEntityAttribute('EpisodeSummary');
    return roleType == RoleType.CLINICAL &&
      canAllowSeeEpisodeSummary &&
      episodeSummary ? (
      <Box px="24px" py="14px">
        <Typography variant="body2" pb="15px">
          Episode Summary:
        </Typography>
        <StyledSummaryBox>{episodeSummary}</StyledSummaryBox>
      </Box>
    ) : null;
  };

  return (
    <StyledContainer>
      <PatientMainDetails
        patient={patient}
        variant={variant}
        episodeLabelHasBorder={episodeLabelHasBorder ?? false}
        tags={tags}
        patientTopReminder={patientTopReminder}
      />
      <EpisodeId
        episodeLabelHasBorder={episodeLabelHasBorder ?? false}
        episodeNumber={checklist?.episodeNumber}
      />
      <EpisodeDetails
        episodeLabelHasBorder={!!episodeLabelHasBorder}
        storageType={storageType}
        serviceLocation={serviceLocation as string}
        diagnosisCodes={diagnosisCodes}
      />

      {variant !== 'small' ? (
        <StyledSmallWrapper>
          <Info patient={patient} backgroundColor={infoBackgroundColor} />
          <PatientOutpatientInpatient
            OutInPatientAuthorizations={OutInPatientAuthorizations}
            variant={variant}
          />
          <StyledPatientNotesWrapper>
            <PatientNotes patient={patient} attributes={attributes} />
          </StyledPatientNotesWrapper>
          {renderEpisodeSummary()}
        </StyledSmallWrapper>
      ) : null}
      <StyledButtonsWrapper
        $variant={variant}
        $isPrintingOpen={isOptimizedView}
      >
        {!isReadOnlyWorkflow && !isOptimizedView && (
          <PatientActionButtons
            hideQuickNote={hideQuickNote}
            patientId={patient?.id}
            editingChecklistId={checklist?.id}
            episodeId={checklist?.episodeId as number}
          />
        )}
      </StyledButtonsWrapper>
    </StyledContainer>
  );
};

export default PatientCard;
