import { getUtcTimeFromCareSiteTime } from 'util/helpers/dateTimeHelpers';

export const handleDateTimeChange = (
  newDate: Date | null | string | undefined | unknown,
  onValueChange: (value: string) => void
) => {
  const newDateTime = newDate as Date;
  if (newDateTime) {
    const utcDateTime = getUtcTimeFromCareSiteTime(newDateTime);
    onValueChange(utcDateTime.toISOString());
  }
};
