import {
  CheckboxRadioButtonComponent,
  ChecklistProblemViewModel,
} from 'graphql/graphqlTypes';
import { useAddChecklistProblemsMutation } from 'graphql/hooks/addChecklistProblems';
import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ICheckBoxRadioSelection } from '../../Items/Orderable';
import { IComponentProps } from '../types';
import CheckboxButton from './CheckboxButton';
import RadioButton from './RadioButton';
import { CHECKBOX_RADIOBUTTON_TYPE } from './types';
import { createSelector } from '@reduxjs/toolkit';

export interface ICheckboxRadioButtonProps
  extends IComponentProps<CheckboxRadioButtonComponent> {
  sectionItemId: string;
  hasCondition: (value: string) => boolean;
  selected: { [groupId: string]: ICheckBoxRadioSelection[] };
  autoSave: boolean;
  addProblem?: (problemId: number) => void;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
  isReadOnly: boolean;
}

const CheckboxRadioComponent = (props: ICheckboxRadioButtonProps) => {
  const {
    component,
    categoryId,
    sectionItemId,
    selected,
    hasCondition,
    handleChecklistInputChange,
    isActionSelected,
    storageType,
    autoSave,
    carePlanProblems,
    updateCarePlanProblems,
    isReadOnly,
  } = props;

  const checklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklist?.id
  );

  const allProblemsSelector = createSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklist?.entities,
    (entities) => entities?.find((x) => x.name === 'Problem')?.values ?? []
  );

  const allProblems = useSelector(allProblemsSelector);

  const [addChecklistProblems] = useAddChecklistProblemsMutation();

  const addProblem = (problemId: number) => {
    const alreadyAdded = carePlanProblems!.find(
      (x) => Number(x.id) === problemId
    );
    if (alreadyAdded) {
      return;
    }
    const newProblem = allProblems?.find((x) => x.id === problemId);
    if (!newProblem) {
      return;
    }
    addChecklistProblems({
      checklistId: Number(checklistId),
      problemIds: [problemId],
    });
    const newProblems = [...carePlanProblems!];
    newProblems.push({
      id: newProblem.id,
      name: newProblem.name,
      isActive: true,
      inCarePlan: false,
      carePlanState: null,
    } as unknown as ChecklistProblemViewModel);
    if (updateCarePlanProblems) {
      updateCarePlanProblems(newProblems);
    }
  };

  if (component.type === CHECKBOX_RADIOBUTTON_TYPE.RADIOBUTTON) {
    return (
      <RadioButton
        component={component}
        categoryId={categoryId}
        sectionItemId={sectionItemId}
        selected={selected}
        storageType={storageType}
        handleChecklistInputChange={handleChecklistInputChange}
        hasCondition={hasCondition}
        isActionSelected={isActionSelected}
        autoSave={autoSave}
        addProblem={addProblem}
        isReadOnly={isReadOnly}
      />
    );
  }
  return (
    <CheckboxButton
      component={component}
      categoryId={categoryId}
      sectionItemId={sectionItemId}
      selected={selected}
      storageType={storageType}
      handleChecklistInputChange={handleChecklistInputChange}
      hasCondition={hasCondition}
      isActionSelected={isActionSelected}
      autoSave={autoSave}
      addProblem={addProblem}
      isReadOnly={isReadOnly}
    />
  );
};

export default CheckboxRadioComponent;
