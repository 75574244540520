import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  addChecklistItem as addChecklistItemAction,
  addChecklistScoringValue,
  addServiceLocation,
  addStoplights,
  removeChecklistItem as removeChecklistItemAction,
  removeStoplights,
  setChecklistCheckboxRadioButtonComponentValue,
  setChecklistComponentValidation,
  setChecklistComponentValue,
  setChecklistItemSaving,
  setChecklistReminderValue,
  setDateTimeChecklistComponentValue,
  updateExpressions,
  updateSelectedActions,
  updateStoplightsState,
} from 'store/actions/checklistSlice';
import { Box, Button, IconButton, Typography } from '@mui/material';
import StopLight, { stoplightStatus } from '../../Components/StopLight';
import { IState } from 'store';
import { IChecklistQualityMeasure } from 'backend/types/checklist';
import { CHECKBOX_RADIOBUTTON_TYPE } from '../../Components/RadioCheckBoxButton/types';
import { buildLineStyle, getGroupId, getStoplightsStatus } from './helpers';
import { ChecklistStorageType, ServiceLocationType } from 'store/actions/types';
import { ATTRIBUTE_TYPE } from 'backend/types/entityAttributeDef';
import Loader from 'components/loader';
import { getStoplights } from 'pages/workflow/helpers';
import { OrderableComponent } from './OrderableComponent';
import styled from 'styled-components';
import {
  BaseComponent,
  CheckboxRadioButtonComponent,
  ChecklistItem,
  ChecklistItemOrderable,
  ChecklistProblemViewModel,
  ChecklistViewModel,
  ComponentsLine,
  ConditionTarget,
  EntityAttributeComponent,
  ListStyleType,
  Maybe,
  OrderableDependentConditionType,
  QualityMeasure,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { useAddQuickNoteActionMutation } from 'graphql/hooks/addQuickNoteAction';
import { useAddChecklistActionMutation } from 'graphql/hooks/addChecklistAction';
import { useRemoveChecklistActionMutation } from 'graphql/hooks/removeChecklistAction';
import {
  InlineBox,
  InlineGrid,
  Line,
  StyledRepeaterWrapper,
} from 'components/actions/sections/SectionBody/Items/Orderable/StyledComponents';
import { usePrevious } from 'hooks';
import { formatDateTime, getCareSiteNow } from 'util/helpers/dateTimeHelpers';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import { useSelectAction } from '../SelectActionMutation.helpers';

const StyledStoplightContainer = styled('div')({
  display: 'flex',
  paddingRight: '14px',
  marginLeft: '1px',
  marginTop: '-5px',
});
const StyledOrderableContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 12px;
  align-items: center;
`;

const StyledFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Spacer = styled.div`
  width: 12px;
`;

export interface IChecklistItemOrderableProps {
  item: ChecklistItemOrderable;
  sectionItemId: string;
  qualityMeasure?: IChecklistQualityMeasure;
  categoryId: string;
  storageType: ChecklistStorageType;
  autoSave: boolean;
  orderableIndex: number;
  isReadOnly: boolean;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  removeRepeatingItem: (item: ChecklistItem, index: number) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

export interface IRadioSelection {
  radioId: string;
  radioValue: string;
  radioName: string;
}

export interface ICheckBoxRadioSelection {
  id: string;
  uniqueID: string;
  score?: string | null;
  scoringGroup?: string | null;
}

const Orderable = (props: IChecklistItemOrderableProps) => {
  const {
    item,
    categoryId,
    sectionItemId,
    storageType,
    carePlanProblems,
    isReadOnly,
    updateCarePlanProblems,
  } = props;

  const dispatch = useDispatch();

  const { selectActionExtended, isSuccess: savingChecklistSuccess } =
    useSelectAction();

  const actionSelected = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].selectedActions[item.uid]
  );

  const [
    addChecklistItem,
    {
      isLoading: isAddingItem,
      data: addChecklistItemResult,
      isSuccess: isSuccessAddChecklistItem,
    },
  ] = useAddChecklistActionMutation();

  const [
    addQuickNoteItem,
    {
      isLoading: isAddingQNItem,
      data: addQuickNoteItemResult,
      isSuccess: isSuccessAddQuickNoteItem,
    },
  ] = useAddQuickNoteActionMutation();

  const [removeChecklistItem, { isLoading: isRemovingItem }] =
    useRemoveChecklistActionMutation();

  const { updateActionValueExtended, isSuccess: savingInputSuccess } =
    useUpdateActionValue();

  const stoplights = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].stoplightsRemaining
  );

  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );

  const isSaving = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].isSaving
  );
  const prevSaving = usePrevious(isSaving);

  const stoplightStatusCompleted = getStoplightsStatus(stoplights, item);

  const [showRemove, setShowRemove] = useState(false);

  const getCheckboxRadioButtonSelectedValue = (): {
    [groupId: string]: ICheckBoxRadioSelection[];
  } =>
    item.lines
      .flatMap((x) => x.components)
      .filter((x) => x.componentType === 'CheckboxRadioButtonComponent')
      .map((x) => x as CheckboxRadioButtonComponent)
      .filter((x) => x.selected)
      .reduce(
        (acc: { [groupId: string]: ICheckBoxRadioSelection[] }, component) => ({
          ...acc,
          [getGroupId(component, sectionItemId)]: [
            ...(acc[getGroupId(component, sectionItemId)] || []),
            {
              id: component.id,
              uniqueID: component.uniqueID,
              score: component.score,
              scoringGroup: component.scoringGroup,
            },
          ],
        }),
        {}
      );

  const checkboxRadioButtonSelectedValue =
    getCheckboxRadioButtonSelectedValue();

  const [selectedValue, setSelectedValue] = useState<{
    [groupId: string]: ICheckBoxRadioSelection[];
  }>(checkboxRadioButtonSelectedValue);

  const resetComponentValidation = (componentId: string, fieldName: string) => {
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: componentId,
          error: '',
          fieldName: fieldName,
          categoryId: categoryId,
          isValid: true,
        },
      })
    );
  };

  const resetOrderableValidation = () => {
    lines.forEach((line) => {
      line.components.forEach((component) => {
        if (component.componentType === 'ReminderComponent') {
          resetComponentValidation(
            component.uniqueID + 'user',
            component.userDefinedId || component.componentType
          );
          resetComponentValidation(
            component.uniqueID + 'date',
            component.userDefinedId || component.componentType
          );
        } else if (component.componentType === 'CheckboxRadioButtonComponent') {
          const name = getGroupId(
            component as CheckboxRadioButtonComponent,
            sectionItemId
          );
          resetComponentValidation(
            name,
            component.userDefinedId || component.componentType
          );
        } else if (component.componentType === 'EntityAttributeComponent') {
          const attributeType = checklist?.attributeTypes?.find(
            (attr) =>
              attr.fullName ===
              (component as EntityAttributeComponent).fieldName
          );
          if (attributeType) {
            if (attributeType?.type === ATTRIBUTE_TYPE.BOOL) {
              const name = `bool_${component.id}_${sectionItemId}`;
              resetComponentValidation(
                name,
                component.userDefinedId || component.componentType
              );
            } else {
              resetComponentValidation(
                component.uniqueID,
                component.userDefinedId || component.componentType
              );
            }
          }
        } else {
          resetComponentValidation(
            component.uniqueID,
            component.userDefinedId || component.componentType
          );
        }
      });
    });
  };

  const handleSelectAction = (isSelected: boolean, isDirectSelect: boolean) => {
    if (isReadOnly) {
      return;
    }

    const actions: { actionId: string; selected: boolean }[] = [
      { actionId: item.uid, selected: isSelected },
    ];

    if (item.qualityMeasure) {
      if (item.qualityMeasure.userChoice === 1) {
        stoplights
          .filter((sl) => sl.id === item.qualityMeasure?.id)
          .flatMap((sl) => sl.itemsWithStoplights)
          .filter((sl) => sl.id !== item.uid)
          .forEach((sl) => {
            actions.push({ actionId: sl.id, selected: false });
          });
      }
      dispatch(
        updateStoplightsState({
          storageType: storageType,
          actions: actions,
        })
      );
    }

    dispatch(
      updateSelectedActions({
        storageType,
        actions,
        isDirectSelect,
        careSiteNow: getCareSiteNow(),
      })
    );

    if (!isSelected) {
      resetOrderableValidation();
    }

    if (item.scoringSystemGroup && item.score) {
      dispatch(
        addChecklistScoringValue({
          storageType,
          scoringGroupId: item.scoringSystemGroup.toString(),
          score: item.score * (isSelected ? 1 : -1),
        })
      );
    }

    actions.forEach((action) => {
      if (props.autoSave) {
        const data = {
          checklistId: Number(checklist?.id) ?? 0,
          isSelected: action.selected,
          id: action.actionId,
        };
        selectActionExtended(data, action.actionId);
        if (prevSaving !== isSaving) {
          dispatch(
            setChecklistItemSaving({
              storageType,
              isSaving: true,
            })
          );
        }
      } else {
        props.selectChecklistItemOnSave(
          item,
          props.orderableIndex,
          action.selected
        );
      }
    });
  };

  const handleRadioChange = (
    newValue: ICheckBoxRadioSelection,
    value: string,
    previousValues: ICheckBoxRadioSelection[],
    currStorageType: ChecklistStorageType
  ) => {
    if (value !== 'true') {
      throw new Error(`Invalid radio button value '${value}'.`);
    }

    const [previousValue] = previousValues;
    if (newValue.scoringGroup) {
      let score = Number(newValue.score);
      if (
        previousValue &&
        previousValue.scoringGroup === newValue.scoringGroup &&
        previousValue.score
      ) {
        score = score - (Number(previousValue.score) || 0);
      }
      dispatch(
        addChecklistScoringValue({
          storageType: currStorageType,
          scoringGroupId: newValue.scoringGroup,
          score,
        })
      );
    }
    return { newValues: [newValue] };
  };

  const handleCheckboxChange = (
    previousValues: ICheckBoxRadioSelection[],
    newValue: ICheckBoxRadioSelection,
    value: string,
    checkboxRadioButton: CheckboxRadioButtonComponent,
    currStorageType: ChecklistStorageType
  ) => {
    const isChecked = value === 'true';

    const newValues = isChecked
      ? [...previousValues.concat([newValue])]
      : [
          ...previousValues.filter(
            (selection) => selection.id !== checkboxRadioButton.id
          ),
        ];

    if (checkboxRadioButton.scoringGroup) {
      dispatch(
        addChecklistScoringValue({
          storageType: currStorageType,
          scoringGroupId: checkboxRadioButton.scoringGroup,
          score: Number(checkboxRadioButton.score) * (isChecked ? 1 : -1),
        })
      );
    }
    return { newValues };
  };

  const handleCheckboxRadioChange = (
    checkboxRadioButton: CheckboxRadioButtonComponent,
    value: string
  ) => {
    if (
      checkboxRadioButton.type !== CHECKBOX_RADIOBUTTON_TYPE.RADIOBUTTON &&
      checkboxRadioButton.type !== CHECKBOX_RADIOBUTTON_TYPE.CHECKBOX
    ) {
      return;
    }

    const groupId = getGroupId(checkboxRadioButton, sectionItemId);

    const previousValues = selectedValue[groupId] || [];
    const newValue: ICheckBoxRadioSelection = {
      id: checkboxRadioButton.id,
      uniqueID: checkboxRadioButton.uniqueID,
      score: checkboxRadioButton.score,
      scoringGroup: checkboxRadioButton.scoringGroup,
    };

    const { newValues } = {
      [CHECKBOX_RADIOBUTTON_TYPE.RADIOBUTTON]: () =>
        handleRadioChange(newValue, value, previousValues, storageType),
      [CHECKBOX_RADIOBUTTON_TYPE.CHECKBOX]: () =>
        handleCheckboxChange(
          previousValues,
          newValue,
          value,
          checkboxRadioButton,
          storageType
        ),
    }[checkboxRadioButton.type]();

    setSelectedValue(
      Object.assign([], selectedValue, {
        [groupId]: newValues,
      })
    );
  };

  const handleChecklistInputChange = (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string,
    fieldName?: string | null,
    itemUid?: string
  ) => {
    if (isReadOnly) {
      return;
    }
    if (field.componentType === 'DropdownComponent') {
      const serviceLocation = getServiceLocation(value);
      if (serviceLocation) {
        dispatch(
          addServiceLocation({
            storageType,
            serviceLocation,
          })
        );
      }
    }
    if (
      field.componentType === 'CheckboxRadioButtonComponent' &&
      typeof value === 'boolean'
    ) {
      handleCheckboxRadioChange(
        field as CheckboxRadioButtonComponent,
        value.toString()
      );
      !actionSelected && handleSelectAction(true, false);
    } else {
      !actionSelected && handleSelectAction(true, false);
    }

    const valueStr = value?.toString() ?? '';

    updateChecklistItemInputValue(valueStr, field, componentId, itemUid);
    setComponentValue(field, valueStr, componentId, fieldName);
  };

  const setComponentValue = (
    field: BaseComponent,
    valueStr: string,
    componentId?: string,
    fieldName?: string | null
  ) => {
    switch (field.componentType) {
      case 'ReminderComponent': {
        dispatch(
          setChecklistReminderValue({
            storageType,
            uniqueID: field.uniqueID,
            value: valueStr,
            componentId: componentId ?? '',
          })
        );
        break;
      }
      case 'CheckboxRadioButtonComponent': {
        const groupId = getGroupId(
          field as CheckboxRadioButtonComponent,
          sectionItemId
        );
        dispatch(
          setChecklistCheckboxRadioButtonComponentValue({
            storageType,
            uniqueID: field.uniqueID,
            value: valueStr,
            selectedValue: selectedValue[groupId] || [],
            field: field,
            careSiteNow: getCareSiteNow(),
          })
        );
        break;
      }
      case 'DateTimeComponent':
      case 'DateComponent':
        {
          dispatch(
            setDateTimeChecklistComponentValue({
              storageType,
              uniqueID: field.uniqueID,
              value: valueStr,
              field: field,
              careSiteNow: getCareSiteNow(),
              fieldName: fieldName,
            })
          );
        }
        break;
      default: {
        dispatch(
          setChecklistComponentValue({
            storageType,
            uniqueID: field.uniqueID,
            value: valueStr,
            field: field,
            careSiteNow: getCareSiteNow(),
          })
        );
        break;
      }
    }
  };

  const getServiceLocation = (value: string | boolean) => {
    const episodeServiceLocation = checklist?.lookups.find(
      (x) => x.name == 'EpisodeServiceLocation'
    );
    const serviceLocation: ServiceLocationType | undefined =
      episodeServiceLocation?.values?.find(
        (x) => x.id == Number(value)
      ) as ServiceLocationType;

    return serviceLocation;
  };

  const updateChecklistItemInputValue = (
    value: string,
    field: BaseComponent,
    compId?: string,
    itemUid?: string
  ) => {
    const id = itemUid ?? item.uid;
    const componentId = compId ?? field.id;
    let type = 'string';
    let groupId = '';
    let idToRetry = `${id}-${componentId}`;

    if (
      field.componentType === 'ReminderComponent' &&
      compId?.split('-')[1] === 'DateDue'
    ) {
      type = 'datetime';
    }
    switch (field.componentType) {
      case 'DateTimeComponent':
        type = 'datetime';
        break;
      case 'DateComponent':
        type = 'date';
        break;
      case 'CheckboxRadioButtonComponent':
        if (
          'type' in field &&
          field.type === 'radiobutton' &&
          'groupId' in field &&
          (value === 'true' || value === 'false')
        ) {
          idToRetry = id;
          groupId = field.groupId ?? '';
        }
        break;
    }

    const data = {
      checklistId: Number(checklist?.id ?? 0),
      componentId: componentId,
      groupId: groupId,
      id: id,
      type: type,
      value: value,
    };

    update(data, idToRetry);
  };

  const update = (params: UpdateActionValueModelInput, idToRetry: string) => {
    if (props.autoSave) {
      updateActionValueExtended(params, idToRetry);
      if (prevSaving !== isSaving) {
        dispatch(
          setChecklistItemSaving({
            storageType,
            isSaving: true,
          })
        );
      }
    } else {
      props.updateChecklistItemInputValueOnSave(
        item,
        props.orderableIndex,
        params
      );
    }
  };

  const handleAddChecklistRepeatingItem = (uid: string) => {
    addChecklistItem({
      checklistId: Number(checklist?.id ?? 0),
      actionId: uid,
    });
  };

  const handleAddQuickNoteRepeatingItem = (orderableId: string) => {
    addQuickNoteItem({ orderableId: Number(orderableId) });
  };

  const handleRemoveRepeatingItem = (uid: string) => {
    if (!checklist) {
      return;
    }
    const items = [
      ...(checklist.categories.find((x) => x.id === categoryId)?.items ?? []),
    ];
    if (props.autoSave) {
      removeChecklistItem({
        checklistId: Number(checklist.id),
        actionId: uid,
      });
    } else {
      const foundItem = items.find((x) => x.uid === uid);
      if (foundItem) {
        props.removeRepeatingItem(foundItem, props.orderableIndex);
      }
    }
    dispatch(removeChecklistItemAction({ storageType, itemId: uid }));
    dispatch(removeStoplights({ storageType, ids: [uid] }));
    dispatch(updateExpressions({ storageType }));
  };

  const hasDependentConditionCategories = (
    curItem: ChecklistItemOrderable
  ): boolean =>
    curItem.conditions.some(
      (x) =>
        x.type === OrderableDependentConditionType.ByAction &&
        x.targetType === ConditionTarget.Category
    );

  const handleAddItem = (partialChecklist: ChecklistViewModel) => {
    if (!checklist) {
      return;
    }

    const [newItemCategory] = partialChecklist.categories;
    const [newItem] = newItemCategory.items;
    const sectionIndex = checklist.categories.findIndex(
      (cat) => cat.id === newItemCategory.id
    );

    const updatedCategory = checklist.categories[sectionIndex];
    const lastRepeaterIndex = updatedCategory.items.findIndex(
      (curItem) =>
        curItem.type === 'orderable' &&
        Number(curItem.orderableId) === Number(newItem.orderableId) &&
        (curItem as ChecklistItemOrderable).repeaterOptions?.canRepeat &&
        !(curItem as ChecklistItemOrderable).hasRepeater
    );

    if (lastRepeaterIndex === -1) {
      return;
    }

    dispatch(
      addChecklistItemAction({
        storageType,
        item: newItem,
        sectionIndex,
        itemIndex: lastRepeaterIndex,
        bigTableValues: partialChecklist.bigTableValues ?? [],
        lookups: partialChecklist.lookups ?? [],
        entities: partialChecklist.entities ?? [],
        attributeTypes: partialChecklist.attributeTypes ?? [],
      })
    );
    dispatch(updateExpressions({ storageType }));

    const uniqueStoplights = getStoplights(partialChecklist);
    uniqueStoplights &&
      dispatch(
        addStoplights({
          storageType: storageType,
          stoplights: uniqueStoplights,
        })
      );
  };

  useEffect(() => {
    if ((savingChecklistSuccess || savingInputSuccess) && isSaving) {
      dispatch(
        setChecklistItemSaving({
          storageType,
          isSaving: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingChecklistSuccess, savingInputSuccess]);

  useEffect(() => {
    if (isSuccessAddQuickNoteItem) {
      const resultModel = addQuickNoteItemResult?.addQuickNoteAction;
      if (resultModel && checklist) {
        handleAddItem(resultModel as ChecklistViewModel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddQuickNoteItem]);

  useEffect(() => {
    if (isSuccessAddChecklistItem) {
      const resultModel = addChecklistItemResult?.addChecklistAction;
      if (resultModel) {
        handleAddItem(resultModel as ChecklistViewModel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddChecklistItem]);

  useEffect(() => {
    setSelectedValue(checkboxRadioButtonSelectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.lines]);

  const lines = item.lines?.filter((line) => line.components.length > 0);

  const needsPaddingLeft =
    item.listStyleType !== ListStyleType.BulletPoint &&
    item.listStyleType !== ListStyleType.Checkbox;

  const showStopLight = (
    qualityMeasure?: Maybe<QualityMeasure>,
    isCompleted?: boolean | null
  ) => {
    if (qualityMeasure?.userChoice === 1 && isCompleted) {
      return stoplights
        ?.find((sl) => sl.id === item.qualityMeasure?.id)
        ?.itemsWithStoplights.some(
          (sl) => sl.id === item.uid && sl.isCompleted
        );
    }
    return true;
  };

  const isShowStopLight = showStopLight(
    item.qualityMeasure,
    stoplightStatusCompleted
  );

  const stoplightIcon = stoplightStatusCompleted
    ? stoplightStatus.DONE
    : stoplightStatus.INCOMPLETE;

  const renderRepeaterDependants = () => {
    return (
      <InlineBox>
        {item.repeaterOptions?.canRepeat && !item.hasRepeater ? (
          <Box width={18} />
        ) : (
          <>
            <Checkbox
              size="small"
              color="primary"
              data-testid="main-checkbox"
              checked={actionSelected}
              style={{ padding: 0 }}
              disabled={isReadOnly}
              onChange={() => handleSelectAction(!actionSelected, true)}
            />
            {hasDependentConditionCategories(item) && (
              <Icon
                icon={ICONS.Condition_Arrow}
                size={12}
                color={COLORS.BLACK}
                style={{ marginLeft: '5px' }}
              />
            )}
          </>
        )}
      </InlineBox>
    );
  };

  const renderCheckBox = () => {
    return (
      <StyledOrderableContainer>
        {item.qualityMeasure?.measureLevel === 'Required' && (
          <StyledStoplightContainer
            id={`uuid-${item.uid}`}
            data-testid="stoplight"
          >
            {isShowStopLight ? (
              <StopLight status={stoplightIcon} />
            ) : (
              <div style={{ width: '16px' }} />
            )}
          </StyledStoplightContainer>
        )}
        {item.listStyleType === ListStyleType.BulletPoint && (
          <Icon
            icon={ICONS.Low}
            size={18}
            color={COLORS.BLACK}
            style={{ marginTop: '-2px', marginRight: '3px' }}
          />
        )}
        {item.listStyleType === ListStyleType.Checkbox &&
          renderRepeaterDependants()}
        {item.listStyleType === ListStyleType.None && <Spacer />}
      </StyledOrderableContainer>
    );
  };

  const renderLines = () => {
    return lines?.map((line: ComponentsLine, lineIndex: number) => (
      <Line
        key={lineIndex}
        style={buildLineStyle(
          line,
          lineIndex,
          needsPaddingLeft,
          actionSelected
        )}
      >
        {lineIndex === 0 ? renderCheckBox() : null}
        {line.components
          .filter(isVisibleComponent)
          .map((component: BaseComponent, componentIndex: number) => (
            <OrderableComponent
              key={component.uniqueID || componentIndex}
              component={component}
              componentIndex={componentIndex}
              isActionSelected={actionSelected}
              isReadOnly={isReadOnly}
              lastComponentInRow={
                component.uniqueID ===
                line.components[line.components.length - 1].uniqueID
              }
              storageType={storageType}
              stoplightStatusCompleted={stoplightStatusCompleted}
              item={item}
              categoryId={categoryId}
              autoSave={props.autoSave}
              sectionItemId={sectionItemId}
              selectedValue={selectedValue}
              handleChecklistInputChange={handleChecklistInputChange}
              carePlanProblems={carePlanProblems}
              updateCarePlanProblems={updateCarePlanProblems}
            />
          ))}
      </Line>
    ));
  };

  const renderRepeater = () => {
    const repeaterText = `${
      item.createdOn ? formatDateTime(item.createdOn) : ''
    }, ${item.createdBy}`;
    return (
      <>
        {item.repeaterOptions?.canRepeat && item.hasRepeater ? (
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {renderLines()}
              <Typography
                variant="body1"
                style={{ textAlign: 'right', color: COLORS.GREY100 }}
              >
                {repeaterText}
              </Typography>
            </div>
            <StyledRepeaterWrapper>
              <IconButton
                style={{
                  display: showRemove ? 'block' : 'none',
                  padding: '4px',
                }}
                onClick={() => handleRemoveRepeatingItem(item.uid)}
                size="large"
              >
                <Icon icon={ICONS.Remove} size={20} color={COLORS.RED100} />
              </IconButton>
            </StyledRepeaterWrapper>
          </div>
        ) : (
          <>{renderLines()}</>
        )}
      </>
    );
  };

  const isVisibleComponent = (component: BaseComponent): boolean =>
    !(component.displayOnlyWhenSelected && !actionSelected);

  return (
    <Grid
      container
      onMouseEnter={() =>
        setShowRemove(!!(item.repeaterOptions?.canRepeat && item.hasRepeater))
      }
      onMouseLeave={() => setShowRemove(false)}
    >
      <InlineGrid item xs={12}>
        <StyledFlexBox>
          {item.repeaterOptions?.canRepeat && !item.hasRepeater ? (
            <Line style={{ paddingLeft: '36px' }}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => {
                  if (props.autoSave) {
                    handleAddChecklistRepeatingItem(item.uid);
                  } else {
                    handleAddQuickNoteRepeatingItem(item.orderableId);
                  }
                }}
              >
                {`+ ${item.repeaterOptions.buttonLabel}`}
              </Button>
            </Line>
          ) : (
            renderRepeater()
          )}
        </StyledFlexBox>
        <Loader active={isAddingItem || isRemovingItem || isAddingQNItem} />
      </InlineGrid>
    </Grid>
  );
};

export default Orderable;
