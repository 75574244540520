import React from 'react';
import { Typography } from '@mui/material';
import { IChecklistComponentProps } from '.';
import { COLORS } from 'consts/styles';
import { styled } from '@mui/styles';

export interface IFixedTextProps extends IChecklistComponentProps {
  hasStoplights?: boolean | null;
  hasError?: boolean | null;
  value: string;
  color?: string;
  isUpdateActionValueRequestFailed?: boolean;
  hidden?: boolean;
}

const Text = ({ value, className, hidden }: IFixedTextProps) => {
  return (
    <Typography
      variant="body1"
      className={className}
      style={{ whiteSpace: 'normal' }}
      hidden={hidden}
    >
      {value}
    </Typography>
  );
};

const StyledText = styled(Text)({
  color: ({
    color,
    hasError,
    hasStoplights,
    isUpdateActionValueRequestFailed,
  }: IFixedTextProps) =>
    (hasError && hasStoplights) || isUpdateActionValueRequestFailed
      ? COLORS.RED100
      : color ?? COLORS.BLACK,
});

export const FixedText = StyledText;
