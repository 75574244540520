import {
  Box,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  EpisodeState,
  EpisodeTabTypes,
  MedicationCode,
  MedicationCodeType,
  Problem,
} from 'graphql/graphqlTypes';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useGetEpisodeDetailsQuery } from 'graphql/hooks/getEpisodeDetails';
import Loader from 'components/loader';
import { useReopenEpisodeMutation } from 'graphql/hooks/reopenEpisode';
import { useCloseEpisodeMutation } from 'graphql/hooks/closeEpisode';
import ReminderConfirmation from 'components/modal/ReminderConfirmation';
import Information from 'components/modal/Information';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import {
  openPatientDetailsModal,
  updatePatientEpisodeRemindersRows,
  updateShowSuccessMessage,
} from 'store/patientdetails/patientDetailsSlice';
import { useGetLinkEpisodesQuery } from 'graphql/hooks/getLinkEpisodes';
import StyledLink from 'components/actionlink/StyledLink';
import { useGetEpisodeTabTypesQuery } from 'graphql/hooks/getEpisodesTabTypes';
import { getTargetEpisodeTabName } from 'components/home/patientdetails/DialogBody.helpers';
import EpisodePrimaryDetails from './EpisodePrimaryDetails';
import { StyledSummaryBox } from 'components/scenario/patient/PatientCard';
import { forceUpdate } from 'store/home/action';
import { EpisodeTypes } from '../../consts';
import { getEpisodeTabName } from 'components/home/grid/formatters/EpisodeNumberFormatter';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';

export interface IEpisodeDetailsTabProps {
  episodeId: number;
  patientId: number;
  episodeType: EpisodeTypes;
  readonly?: boolean;
  setRefetch: (refetch: boolean) => void;
  oneCaseCanBeOpened: boolean;
  lastCaseCanBeOpened: boolean;
  setEpisodeStateChanged: (refetch: boolean) => void;
  onEpisodeSelectionChange?: (values: Array<number | string>) => void;
}

export const BoldText = styled.b`
  font-weight: 500;
`;

export const StyledUl = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 28px;
`;

export const BigMarginBox = styled(Box)`
  margin-bottom: 42px;
`;
export const SmallMarginBox = styled(Box)`
  margin-bottom: 12px;
`;

export const StyledSelect = styled(Select)({
  paper: {
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
});

export const StyledEpisodeLink = styled(StyledLink)({
  padding: '4px 0px 4px 0px',
});

const EpisodeDetails = (props: IEpisodeDetailsTabProps) => {
  const {
    episodeId,
    patientId,
    episodeType,
    readonly,
    oneCaseCanBeOpened,
    lastCaseCanBeOpened,
    setRefetch,
    setEpisodeStateChanged,
    onEpisodeSelectionChange,
  } = props;
  const dispatch = useDispatch();
  const [reminderConfirmation, changeReminderConfirmation] = useState(false);
  const [showOnlyOneCaseCanBeOpen, setShowOnlyOneCaseCanBeOpen] =
    useState(false);
  const [showOnlyLastCaseCanBeOpen, setShowOnlyLastCaseCanBeOpen] =
    useState(false);
  const { data, isFetching: isFetchingDetails } = useGetEpisodeDetailsQuery(
    {
      episodeId: episodeId,
    },
    { skip: episodeId <= 0, refetchOnMountOrArgChange: true }
  );

  const episode = data?.getEpisode;
  const problems = (data?.getCarePlanProblemsByEpisode ?? []) as Problem[];

  const { data: linkedEpisodeData } = useGetLinkEpisodesQuery(
    {
      episodeId: episodeId,
    },
    {
      skip: episodeId <= 0,
      refetchOnMountOrArgChange: true,
    }
  );

  const linkedEpisodes = linkedEpisodeData?.getLinkEpisodes;

  const { data: epidodesTabType } = useGetEpisodeTabTypesQuery(undefined, {
    skip: episodeId <= 0,
    refetchOnMountOrArgChange: true,
  });

  const tabTypes = epidodesTabType?.getEpisodeTabTypes;

  const MenuProps = {
    style: {
      left: '0',
      marginTop: '2px',
      maxHeight: 350,
      width: 100,
    },
  };
  const [episodeStatus, setEpisodeStatus] = useState<string>('');
  const [reopenEpisode, { isLoading: isOpeningEpisode }] =
    useReopenEpisodeMutation();

  const [
    closeEpisode,
    {
      isLoading: isClosingEpisode,
      isSuccess: isClosedEpisode,
      data: closeEpisodeResult,
    },
  ] = useCloseEpisodeMutation();

  useEffect(() => {
    if (
      isClosedEpisode &&
      closeEpisodeResult &&
      closeEpisodeResult.closeEpisode
    ) {
      const errorMessage = closeEpisodeResult.closeEpisode.message;
      if (closeEpisodeResult.closeEpisode.success) {
        setEpisodeStateChanged(true);
        setEpisodeStatus(EpisodeState.Closed);
        dispatch(
          updatePatientEpisodeRemindersRows({
            episodeId: episodeId,
            reminders: [],
          })
        );
        showSuccessMessage();
      } else if (errorMessage === 'Episode has opened Care Plan Problems') {
        dispatch(
          showErrorPopup({
            message:
              'The Care Plan for this member is still open. Please finish and close all Care Plan documentation before closing.',
            title: 'Close Episode',
          })
        );
      } else if (
        closeEpisodeResult.closeEpisode.message ===
        'Episode has opened Reminders'
      ) {
        changeReminderConfirmation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosedEpisode]);

  const handleRefetchEpisodes = () => {
    if (episodeType === 'cases') {
      dispatch(forceUpdate(true));
      setRefetch(true);
      return;
    }
    setEpisodeStateChanged(true);
  };

  const handleCompleteAndCloseReminder = () => {
    changeReminderConfirmation(false);
    closeEpisode({
      episodeId: Number(episodeId),
      completeReminder: true,
    }).then(() => {
      handleRefetchEpisodes();
    });
  };

  useEffect(() => {
    setEpisodeStatus((episode?.state ?? '') as EpisodeState);
  }, [episode?.state]);

  const showSuccessMessage = () => {
    dispatch(updateShowSuccessMessage(true));
  };

  const handleChangeEpisodeStatus = (value: string) => {
    if (value === EpisodeState.Opened) {
      if (episodeType === 'cases') {
        if (oneCaseCanBeOpened) {
          setShowOnlyOneCaseCanBeOpen(true);
          return;
        }
        if (lastCaseCanBeOpened) {
          setShowOnlyLastCaseCanBeOpen(true);
          return;
        }
      }
      reopenEpisode({
        episodeId: Number(episodeId),
      }).then(() => {
        setEpisodeStateChanged(true);
        setEpisodeStatus(EpisodeState.Opened);
        handleRefetchEpisodes();
        showSuccessMessage();
      });
    } else {
      closeEpisode({
        episodeId: Number(episodeId),
      }).then(() => {
        handleRefetchEpisodes();
      });
    }
  };

  const renderSelectedValue = (value: string): ReactNode => {
    return (
      <Box display="flex" alignItems="center">
        <Box
          style={{
            borderRadius: '50%',
            height: '8px',
            width: '8px',
            marginRight: '8px',
            backgroundColor:
              (value as EpisodeState) === EpisodeState.Opened
                ? COLORS.GREEN100
                : COLORS.GREY60,
          }}
        />
        <Typography variant="body1">
          {(value as EpisodeState) === EpisodeState.Opened ? 'Open' : 'Closed'}
        </Typography>
      </Box>
    );
  };

  const getEntityAttribute = (name: string) =>
    episode?.entityAttributes?.find(
      (attr) => attr?.name?.toLowerCase() === name.toLowerCase()
    )?.value ?? '';

  const canEditEpisodeDetailsState = hasUserPrivilege(
    Privilege.EditEpisodeDetailsState
  );

  const canAllowSeeEpisodeSummary = hasUserPrivilege(
    Privilege.AllowSeeEpisodeSummary
  );

  const episodePathway = getEntityAttribute('Pathway');
  const episodePathwayType =
    episodePathway === ''
      ? episode?.authorizationCategory?.name
      : episodePathway;
  const isInpatientType = episodePathwayType == 'Inpatient';
  const episodeAuthStatus = episode?.authorizationStatus?.name ?? '';
  const episodeAuthStatusReason = getEntityAttribute(
    'AuthorizationStatusReason'
  );
  const episodeDischargeDate = episode?.actualDischargeDate;
  const episodeDischargeLocation = getEntityAttribute('DischargeLocation');

  const appealGrievDetails = (
    <>
      <SmallMarginBox>
        <Typography>
          <BoldText>Service Timing: </BoldText>
          {getEntityAttribute('IncidentServiceTiming')}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Service Type: </BoldText>
          {getEntityAttribute('ServiceType')}
        </Typography>
      </SmallMarginBox>
      <BigMarginBox>
        <Typography>
          <BoldText>Grievance Category: </BoldText>
          {getEntityAttribute('IncidentGrievanceCategory')}
        </Typography>
      </BigMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Provider: </BoldText>
          {episode?.requestingClinician?.name}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Provider Status: </BoldText>
          {getEntityAttribute('RequestingClinicianPar')}
        </Typography>
      </SmallMarginBox>
    </>
  );

  const episodeDetails = (
    <>
      <BigMarginBox>
        <Typography>
          <BoldText>Type: </BoldText>
          {episodePathwayType}
        </Typography>
      </BigMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Requesting Provider: </BoldText>
          {episode?.requestingClinician?.name}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Servicing Provider: </BoldText>
          {episode?.servicingClinician?.name}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Facility: </BoldText>
          {episode?.facility?.name}
        </Typography>
      </SmallMarginBox>
      <BigMarginBox>
        <Typography>
          <BoldText>Admitting Provider: </BoldText>
          {episode?.admittingClinician?.name}
        </Typography>
      </BigMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Authorized Unit Number: </BoldText>
          {episode?.approvedUnits}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Authorized Unit Type: </BoldText>
          {episode?.approvedUnitType?.name}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Decision Status: </BoldText>
          {episodeAuthStatus}
        </Typography>
      </SmallMarginBox>
      <SmallMarginBox>
        <Typography>
          <BoldText>Status Reason: </BoldText>
          {episodeAuthStatusReason}
        </Typography>
      </SmallMarginBox>
      {isInpatientType && (
        <>
          <SmallMarginBox>
            <Typography>
              <BoldText>Discharge Date: </BoldText>
              {formatDate(episodeDischargeDate)}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Discharge Location: </BoldText>
              {episodeDischargeLocation}
            </Typography>
          </SmallMarginBox>
        </>
      )}
    </>
  );

  const selectedTabName = getEpisodeTabName(episodeType);

  const handleClick = (
    patientId: number,
    episodeId: number,
    episodeTypeId: number
  ) => {
    const targetTabName = getTargetEpisodeTabName(
      episodeTypeId,
      tabTypes as EpisodeTabTypes[]
    );

    if (targetTabName === selectedTabName) {
      onEpisodeSelectionChange?.(
        episodeId !== undefined ? [episodeId, episodeId] : []
      );
    } else {
      dispatch(
        openPatientDetailsModal({
          patientId,
          episodeId,
          activeTabName: targetTabName,
          activeSubTabName: '',
        })
      );
    }
  };

  const populateMedicationCodes = (
    medicationCodes: Array<MedicationCode> | null,
    medicationCodeType: MedicationCodeType
  ): JSX.Element[] | undefined => {
    return medicationCodes?.map((item, index) => (
      <li key={medicationCodeType.toString() + index.toString()}>
        <Typography>{`${item?.code}: ${item?.shortDescription}`}</Typography>
      </li>
    ));
  };

  return (
    <div data-cy="details-tab" style={{ height: '100%', paddingTop: '24px' }}>
      {!isFetchingDetails && (
        <Grid
          container
          spacing={3}
          data-cy="details-grid"
          style={{ height: '100%' }}
        >
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${COLORS.GREY25}`,
              minHeight: '50vh',
              height: '100%',
              paddingTop: 0,
            }}
          >
            <Box mb="34px" display="flex" alignItems="center">
              <Typography variant="body2" style={{ paddingRight: '6px' }}>
                State:
              </Typography>
              <Box>
                <StyledSelect
                  value={episodeStatus}
                  variant="outlined"
                  disabled={readonly || !canEditEpisodeDetailsState}
                  style={{ width: '110px' }}
                  onChange={(event) =>
                    handleChangeEpisodeStatus(event.target.value as string)
                  }
                  MenuProps={{
                    ...MenuProps,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  renderValue={(selected) =>
                    selected ? renderSelectedValue(selected as string) : ''
                  }
                >
                  <MenuItem value={EpisodeState.Opened}>
                    <ListItemText style={{ marginLeft: '9px' }}>
                      Open
                    </ListItemText>
                  </MenuItem>
                  <MenuItem value={EpisodeState.Closed}>
                    <ListItemText style={{ marginLeft: '9px' }}>
                      Closed
                    </ListItemText>
                  </MenuItem>
                </StyledSelect>
              </Box>
            </Box>
            <EpisodePrimaryDetails
              episodeType={episodeType}
              episode={episode}
              canAllowSeeEpisodeSummary={canAllowSeeEpisodeSummary}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${COLORS.GREY25}`,
              paddingTop: 0,
            }}
          >
            {episodeType === 'cases' ? (
              <>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Referral Date: </BoldText>
                    {formatDate(getEntityAttribute('CMReferralDate'))}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Start Date: </BoldText>
                    {formatDate(episode?.createdOn)}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Referral Reason: </BoldText>
                    {getEntityAttribute('CMReferralReason')}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Referral Source: </BoldText>
                    {getEntityAttribute('CMReferralSource')}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Engaged Date: </BoldText>
                    {formatDate(getEntityAttribute('CMEngagedDate'))}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Last Assessment Date: </BoldText>
                    {formatDate(getEntityAttribute('AssessmentDate'))}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Closed Date: </BoldText>
                    {formatDate(episode?.closedOn)}
                  </Typography>
                </SmallMarginBox>
                <SmallMarginBox>
                  <Typography>
                    <BoldText>Close Reason: </BoldText>
                    {getEntityAttribute('CMStatusReason')}
                  </Typography>
                </SmallMarginBox>
                <Box display="block">
                  <Typography variant="body2">Problem List: </Typography>
                  <Box display="flex">
                    <StyledUl>
                      {problems.map((problem) => (
                        <li key={problem.id}>
                          <Typography>{problem.name}</Typography>
                        </li>
                      ))}
                    </StyledUl>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                {episodeType === 'appealgriev'
                  ? appealGrievDetails
                  : episodeDetails}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 0,
            }}
          >
            {episodeType === 'cases' && canAllowSeeEpisodeSummary && (
              <>
                <SmallMarginBox>
                  <Typography variant="body2">Episode Summary: </Typography>
                </SmallMarginBox>
                <BigMarginBox>
                  <StyledSummaryBox>
                    {getEntityAttribute('EpisodeSummary')}
                  </StyledSummaryBox>
                </BigMarginBox>
              </>
            )}
            {episodeType !== 'cases' && (
              <>
                <SmallMarginBox>
                  <Typography variant="body2">Proc Codes: </Typography>
                </SmallMarginBox>
                <BigMarginBox>
                  <StyledUl>
                    {populateMedicationCodes(
                      episode?.procedureCodesAll as MedicationCode[],
                      MedicationCodeType.CptHcpcs
                    )}
                  </StyledUl>
                </BigMarginBox>

                <SmallMarginBox>
                  <Typography variant="body2">ICD Codes: </Typography>
                </SmallMarginBox>
                <BigMarginBox>
                  <StyledUl>
                    {populateMedicationCodes(
                      episode?.diagnosisCodesAll as MedicationCode[],
                      MedicationCodeType.Icd_10Cm
                    )}
                  </StyledUl>
                </BigMarginBox>
              </>
            )}

            <>
              <SmallMarginBox>
                <Typography variant="body2">Linked Episodes: </Typography>
              </SmallMarginBox>
              {linkedEpisodes &&
                linkedEpisodes?.map(
                  (linkedEpisode) =>
                    episodeId != linkedEpisode?.episodeId && (
                      <StyledEpisodeLink
                        key={linkedEpisode?.episodeId}
                        onClick={() => {
                          handleClick(
                            patientId,
                            linkedEpisode?.episodeId as number,
                            linkedEpisode?.episodeTypeId as number
                          );
                        }}
                        data-cy="link-episode"
                      >
                        {linkedEpisode?.episodeNumber}
                      </StyledEpisodeLink>
                    )
                )}
            </>
          </Grid>
        </Grid>
      )}
      <ReminderConfirmation
        open={reminderConfirmation}
        reminderCount={closeEpisodeResult?.closeEpisode?.reminderCount ?? 0}
        onCompleteAndClose={handleCompleteAndCloseReminder}
        onCancel={() => changeReminderConfirmation(false)}
      />
      <Information
        open={showOnlyOneCaseCanBeOpen}
        okEvent={() => {
          setShowOnlyOneCaseCanBeOpen(false);
        }}
        title="Can't Open Case"
      >
        <div>You can only have one case open at the same time</div>
      </Information>
      <Information
        open={showOnlyLastCaseCanBeOpen}
        okEvent={() => {
          setShowOnlyLastCaseCanBeOpen(false);
        }}
        title="Can't Open Case"
      >
        <div>You can only reopen the most recent case</div>
      </Information>
      <Loader
        active={isFetchingDetails || isOpeningEpisode || isClosingEpisode}
      />
    </div>
  );
};

export default EpisodeDetails;
