import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from '@mui/material';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import {
  setExpandedProblemIds,
  updateCarePlanModelSuccess,
} from 'store/actions/checklistSlice';
import { useAddChecklistProblemsMutation } from 'graphql/hooks/addChecklistProblems';
import { useUpdateChecklistProblemMutation } from 'graphql/hooks/updateChecklistProblem';
import { useDeleteChecklistProblemMutation } from 'graphql/hooks/deleteChecklistProblem';
import { useCreateOrUpdateCarePlanMutation } from 'graphql/hooks/createOrUpdateCarePlan';
import {
  CarePlanState,
  ChecklistProblemViewModel,
  ChecklistViewModel,
  CreateOrUpdateCarePlanActionContextInput,
  Problem,
} from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import Loader from 'components/loader';
import CarePlanProblemsGrid from './carePlanProblemsGrid';
import ProblemsDialog from './problemsDialog';
import { getCarePlanStatus, problemsColumnNames } from './problems.helper';

export interface ICarePlanProps {
  isReadOnly?: boolean;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

const CarePlan = (props: ICarePlanProps) => {
  const { isReadOnly, carePlanProblems, updateCarePlanProblems } = props;
  const dispatch = useDispatch();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const expandedProblemIds = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]
        .expandedProblemIds
  );
  const patientId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]?.checklist
        ?.patientId
  );
  const checklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]?.checklist
        ?.id
  );
  const carePlanChecklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.checklist
  );
  const carePlanChecklistId = carePlanChecklist?.id;
  const [
    createOrUpdateCarePlan,
    {
      isLoading: isUpdatingCarePlan,
      isSuccess: isSuccessUpdatedCarePlan,
      data: carePlanData,
    },
  ] = useCreateOrUpdateCarePlanMutation();

  const [addChecklistProblems] = useAddChecklistProblemsMutation();
  const [updateChecklistProblem] = useUpdateChecklistProblemMutation();
  const [deleteChecklistProblem] = useDeleteChecklistProblemMutation();

  const changeInCarePlan = (id: number, state: boolean) => {
    const newCarePlan = carePlanProblems!.map((x) =>
      Number(x.id) === id
        ? {
            ...x,
            inCarePlan: state,
            carePlanState: state ? CarePlanState.Opened : null,
          }
        : x
    );
    updateCarePlanProblems?.(newCarePlan);

    const problemIds = state
      ? [...expandedProblemIds, id]
      : expandedProblemIds.filter((x) => x !== id);
    dispatch(setExpandedProblemIds(problemIds));

    const model = {
      checklistId: Number(checklistId ?? 0),
      patientId: patientId,
      problemId: id,
      addToCarePlan: state,
      note: newCarePlan.find((x) => Number(x.id) === id)?.note,
    } as CreateOrUpdateCarePlanActionContextInput;

    createOrUpdateCarePlan({ model });
  };

  const changeComment = (id: number, comment: string) => {
    const newCarePlans = carePlanProblems!.map((x) =>
      Number(x.id) === id ? { ...x, note: comment } : x
    );
    const inCarePlan = carePlanProblems?.find(
      (x) => Number(x.id) === id
    )?.inCarePlan;
    updateChecklistProblem({
      problem: {
        checklistId: inCarePlan
          ? Number(carePlanChecklistId)
          : Number(checklistId),
        problemId: id,
        note: comment,
        isCarePlanTab: true,
      },
    });
    updateCarePlanProblems?.(newCarePlans);
  };

  const deleteProblem = (id: number) => {
    const newCarePlans = carePlanProblems!.filter((x) => Number(x.id) !== id);
    deleteChecklistProblem({ checklistId: Number(checklistId), problemId: id });
    updateCarePlanProblems?.(newCarePlans);
  };

  const onProblemsSelect = (selectedProblems: Problem[]) => {
    setShowAddDialog(false);
    const newProblems = [...carePlanProblems!];
    selectedProblems.forEach((item) => {
      newProblems.push({
        id: item.id,
        name: item.name,
        isActive: true,
        inCarePlan: false,
        carePlanState: null,
      } as unknown as ChecklistProblemViewModel);
    });
    addChecklistProblems({
      checklistId: Number(checklistId),
      problemIds: selectedProblems.map((x) => Number(x.id)),
    });
    updateCarePlanProblems?.(newProblems);
  };

  const problemsColumns = [
    {
      name: problemsColumnNames.name,
      title: 'Problem Name',
    },
    {
      name: problemsColumnNames.comment,
      title: 'Comment',
      getCellValue: (row: ChecklistProblemViewModel) => ({
        id: Number(row?.id ?? 0),
        note: row.note,
        inCarePlan: row.inCarePlan,
        isReadOnly: isReadOnly,
        changeComment,
      }),
    },
    {
      name: problemsColumnNames.status,
      title: 'Status',
      getCellValue: (row: ChecklistProblemViewModel) =>
        getCarePlanStatus(carePlanChecklist, row),
    },
  ];

  const inCarePlanProblemColumn = {
    name: problemsColumnNames.inCarePlan,
    title: 'Care Plan',
    getCellValue: (row: ChecklistProblemViewModel) => ({
      id: Number(row?.id ?? 0),
      inCarePlan: row.inCarePlan,
      isReadOnly: isReadOnly ?? row.isReadOnly,
      changeInCarePlan,
    }),
  };

  const deleteProblemColumn = {
    name: problemsColumnNames.id,
    title: ' ',
    getCellValue: (row: ChecklistProblemViewModel) => ({
      id: Number(row?.id ?? 0),
      inCarePlan: row.inCarePlan,
      isReadOnly: isReadOnly,
      deleteProblem,
    }),
  };

  const carePlanProblemColumns = !isReadOnly
    ? [...problemsColumns, inCarePlanProblemColumn, deleteProblemColumn]
    : problemsColumns;

  useEffect(() => {
    if (isSuccessUpdatedCarePlan) {
      dispatch(
        updateCarePlanModelSuccess({
          checklist: carePlanData?.createOrUpdateCarePlan as ChecklistViewModel,
          problems: carePlanProblems as ChecklistProblemViewModel[],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanData]);

  if (isUpdatingCarePlan) {
    return <Loader active={true} />;
  }

  return (
    <Grid container>
      <Grid item>
        <Box mb="32px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="16px"
          >
            <CarePlanProblemsGrid
              rows={carePlanProblems!}
              columns={carePlanProblemColumns}
              getRowId={(row) => Number(row.id)}
              isReadOnly={isReadOnly as boolean}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="16px"
          >
            {!isReadOnly ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  startIcon={
                    <Icon
                      icon={ICONS.Add}
                      size={14}
                      color={COLORS.SYMPHONY_BLUE}
                    />
                  }
                  onClick={() => setShowAddDialog(true)}
                >
                  Add Problem
                </Button>
                {showAddDialog ? (
                  <ProblemsDialog
                    open={showAddDialog}
                    excludeIds={carePlanProblems!.map((x) => Number(x.id))}
                    onSelect={onProblemsSelect}
                    onClose={() => {
                      setShowAddDialog(false);
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CarePlan;
