import React, { useState } from 'react';
import { Button } from '@mui/material';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

const InCarePlanFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const [inCarePlan, setInCarePlan] = useState(props.value.inCarePlan);

  const handleClick = () => {
    setInCarePlan(!inCarePlan);
    props.value.changeInCarePlan(props.value.id, !inCarePlan);
  };

  return (
    <Button
      color="primary"
      size="small"
      variant="outlined"
      data-testid={`addRemoveCarePlan_${props.value.id}`}
      disabled={props.value.isReadOnly}
      onClick={handleClick}
    >
      {`${inCarePlan ? 'Remove' : 'Add'} Care Plan`}
    </Button>
  );
};

const InCarePlanProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={InCarePlanFormatter} {...props} />
);

export default InCarePlanProvider;
