import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Checkbox } from '@mui/material';
import React from 'react';
import {
  updateDirtyTabs,
  updateOkToContactPhone,
} from 'store/patientdetails/patientDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'consts/styles';
import { IState } from 'store';

interface IOkToContactProps extends ILabelCellProps {
  disabled?: boolean;
}

export const OkToContact = (props: IOkToContactProps) => {
  const { row, value: isOkToContact, disabled } = props;
  const dispatch = useDispatch();
  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  const updateOkToContact = () => {
    dispatch(
      updateOkToContactPhone({
        id: row.type,
        value: !isOkToContact,
      })
    );
    if (!isInEditMode) {
      dispatch(updateDirtyTabs('Background'));
    }
  };

  return (
    <Table.Cell
      {...props}
      style={CellComponentStyle}
      data-cy="table-cell"
      onClick={undefined}
    >
      <Checkbox
        disabled={disabled}
        checked={isOkToContact ?? false}
        color="primary"
        onClick={updateOkToContact}
        style={{ color: disabled ? COLORS.GREY60 : undefined }}
      />
    </Table.Cell>
  );
};
