import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  api,
  useGetPatientCardInfoQuery,
} from 'graphql/hooks/getPatientCardInfo';
import { ChecklistStorageType } from 'store/actions/types';
import Loader from 'components/loader';
import { useSelector } from 'react-redux';
import store, { IState } from 'store';
import { PatientCardWrapper } from 'pages/workflow/PatientCardWrapper';
import ReviewSections from 'components/review/sections/ReviewSections';
import { useGetCheckListAsync } from 'hooks/useGetCheckListAsync';
import { ChecklistViewModel } from 'graphql/graphqlTypes';
export interface IChecklistPreview {
  checklistIds: number[];
  patientId: number;
  storageType: ChecklistStorageType;
  onCompleted?: () => void;
}

const ChecklistPreviewWrapper = ({
  checklistIds,
  storageType,
  patientId,
  onCompleted,
}: IChecklistPreview) => {
  const checklist = useSelector(
    (state: IState) => state.ui.print.documents.nonMozart.checklistViewModel
  );
  const isOptimizedView = useSelector(
    (state: IState) => state.ui.print.documents.isOptimizedView
  );
  const patientDetailsEpisodeId = useSelector(
    (state: IState) => state.patientDetails.episodeId
  );
  const [finalChecklistData, setFinalChecklistData] = useState<
    ChecklistViewModel[]
  >([]);
  const { getChecklists, loading, checklistData, error } =
    useGetCheckListAsync();
  const { data: patientInfo } = api.endpoints.getPatientCardInfo.select({
    patientId: patientId ?? 0,
  })(store.getState());
  const { data: patientData, isFetching: isPatientFetching } =
    useGetPatientCardInfoQuery(
      {
        patientId: patientId ?? 0,
      },
      {
        skip: !patientId && !patientInfo,
      }
    );

  useEffect(() => {
    if (shouldFetch()) {
      getChecklists(checklistIds ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistIds]);
  useEffect(() => {
    if (!error && !loading && checklistData) {
      setFinalChecklistData(checklistData);
    } else {
      setFinalChecklistData(checklist ?? []);
    }
  }, [error, loading, checklistData, checklist]);
  useEffect(() => {
    if (!isPatientFetching && patientData && !loading && checklistData) {
      onCompleted?.();
    }
  }, [checklistData, isPatientFetching, loading, onCompleted, patientData]);
  const shouldFetch = () => {
    if (!checklistIds?.length || !checklist?.length) {
      return true;
    }

    return (
      checklist.filter(
        (x) =>
          x.episodeId === patientDetailsEpisodeId &&
          checklistIds.map(String).includes(x.id)
      ).length !== checklistIds.length
    );
  };
  return (
    <>
      <Loader active={loading || isPatientFetching} />
      <Grid container spacing={0} style={{ position: 'relative' }}>
        <Grid item xs={12}>
          {!isPatientFetching && (
            <PatientCardWrapper
              patientData={patientInfo ?? patientData}
              storageType={storageType}
              isOptimizedView={isOptimizedView}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {finalChecklistData.map((checklistItem) => (
            <ReviewSections
              key={checklistItem?.id}
              checklist={checklistItem}
              storageType={storageType}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
ChecklistPreviewWrapper.displayName = 'ChecklistPreviewWrapper';
export default ChecklistPreviewWrapper;
