import React from 'react';
import { Typography } from '@mui/material';
import { ActionButton } from 'features/letters/list';
import { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { openChecklistFax } from 'store/ui/modals/letters';
import { openChecklist } from 'store/ui/modals/checklist';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { updateNonMozartDocumentState } from 'store/ui/print/documents';
import DocumentsPrintPreview from '../DocumentsPrintPreview';
import { PRINT_SOURCES } from 'backend/types/printSources';
import { IState } from 'store';

export interface ICarePlanHeaderActionsProps {
  lastUpdateBy?: string | null;
  updatedOn?: string | null;
  checklistId: number;
  patientId: number;
  episodeId: number;
  disableActions: boolean;
}

export const CarePlanHeaderActions = ({
  lastUpdateBy,
  updatedOn,
  checklistId,
  patientId,
  episodeId,
  disableActions,
}: ICarePlanHeaderActionsProps) => {
  const dispatch = useDispatch();
  const isPrintOpen = useSelector(
    (state: IState) => state.ui.print.documents.isPrintOpen
  );
  const handlePrint = (checklistId: number) => {
    dispatch(
      updateNonMozartDocumentState({
        episodeId: episodeId,
        selectedChecklistIds: [checklistId],
      })
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Typography variant="subtitle1">Care Plan</Typography>
        {!!checklistId && (
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h6"
              style={{ color: COLORS.GREY100, marginBottom: '4px' }}
            >
              Last update by {lastUpdateBy} on&nbsp;
            </Typography>
            <Typography variant="h5" style={{ color: COLORS.GREY100 }}>
              {updatedOn ? formatDateTime(updatedOn) : null}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <ActionButton
          icon={ICONS.Eye}
          text="View"
          active={!disableActions}
          onClick={() => dispatch(openChecklist({ checklistId }))}
        />
        <ActionButton
          icon={ICONS.Print}
          text="Print"
          active={!disableActions}
          onClick={() => handlePrint(Number(checklistId))}
        />
        <ActionButton
          icon={ICONS.FaxOutline}
          text="Fax"
          active={!disableActions}
          onClick={() =>
            dispatch(openChecklistFax({ checklistId, patientId, episodeId }))
          }
        />
        {isPrintOpen && (
          <DocumentsPrintPreview
            open={isPrintOpen}
            printSource={PRINT_SOURCES.DOCUMENT}
            patientId={patientId}
          />
        )}
      </div>
    </div>
  );
};
