import { Grid } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Sorting } from '@devexpress/dx-react-grid';
import { IEpisodesTabState } from './types';
import { COLORS } from 'consts/styles';
import {
  APPEAL_GRIEVANCE_TAB_STATE_FILTER,
  CASES_TAB_STATE_FILTER,
  EPISODES_TAB_STATE_FILTER,
  ORG_DET_TAB_STATE_FILTER,
} from 'components/constants';
import SubTabs from './subtabscomponent';
import {
  EpisodeTypes,
  getColumnExtensions,
  getColumns,
  getOpenedColumns,
  getOpenedColumnsExtension,
  getRequest,
  mapSubTabKeys,
  moveFlowColumnsExtension,
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeJustAddedReminder,
  setActiveEpisode,
} from 'store/patientdetails/patientDetailsSlice';
import { EpisodeTabHeader } from './EpisodeTabHeader';
import { EpisodeGrid } from './EpisodeGrid';
import { EpisodeListState } from 'backend/types/episodeListState';
import { ILookupValue } from 'backend/types/lookupValue';
import { useGetEpisodeTabModelQuery } from 'graphql/hooks/getEpisodesTabModel';
import {
  GetEpisodeTabDataQuery,
  useLazyGetEpisodeTabDataQuery,
} from 'graphql/hooks/getEpisodesTabData';
import {
  CaseEpisodeViewModel,
  Episode,
  EpisodeState,
  EpisodeType,
  EpisodeWithMozartInfoViewModel,
  PatientDetailsSubTabDefinition,
} from 'graphql/graphqlTypes';
import {
  GetCasesTabDataQuery,
  useLazyGetCasesTabDataQuery,
} from 'graphql/hooks/getCasesTabData';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import Loader from 'components/loader';
import { find } from 'lodash';
import { IState } from 'store';
import { clearDocumentState } from 'store/ui/print/documents';
import { resetMozartState } from 'store/actions/mozartSlice';

export interface IEpisodesTabProps {
  style?: CSSProperties;
  patientId: number;
  showEpisodesWithoutDetails?: boolean;
  showAllTypes?: boolean;
  isDirectOpen: boolean;
  readonly?: boolean;
  showTatColumn: boolean;
  showCounterColumn: boolean;
  selectEpisode?: number;
  editingChecklistId?: string;
  selectedSubTab?: string;
  episodeType: EpisodeTypes;
  onEpisodeSelection?: (episodeId: number, episodeNumber?: string) => void;
  readonlyGrid?: boolean;
  shouldNotOpenSidePanel?: boolean;
  episodeColumns?: { name: string; title: string }[];
  limitGridHeight?: boolean;
  handleEpisodesLengthChange?: (numberOfEpisodes: number) => void;
}

/* eslint-disable-next-line sonarjs/cognitive-complexity */
const EpisodesTab = (props: IEpisodesTabProps) => {
  const {
    patientId,
    showTatColumn,
    showCounterColumn,
    readonly = false,
    readonlyGrid = false,
    isDirectOpen,
    selectEpisode,
    editingChecklistId,
    showAllTypes,
    style,
    episodeType,
    selectedSubTab,
    onEpisodeSelection,
    shouldNotOpenSidePanel,
    episodeColumns,
    limitGridHeight,
    handleEpisodesLengthChange,
  } = props;
  const [episodeTabModel, setTabModel] = useState<IEpisodesTabState>({
    patientId,
    showTatColumn,
    showCounterColumn,
    isDirectOpen,
    selectEpisode,
    episodeListState: EpisodeListState.Open,
  } as IEpisodesTabState);
  const [episodeStateChanged, setEpisodeStateChanged] = useState(false);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState(
    episodeTabModel.isDirectOpen &&
      episodeTabModel.selectEpisode &&
      episodeTabModel.selectEpisode > 0
      ? episodeTabModel.selectEpisode
      : 0
  );

  const { data: model, isFetching: isModelFetching } =
    useGetEpisodeTabModelQuery(
      { tabType: episodeType },
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const dispatch = useDispatch();
  const [setUserPreference, { isLoading: isUpdatingUserPreference }] =
    useSetPreferenceMutation();

  useEffect(() => {
    setTabModel({
      ...episodeTabModel,
      episodeListState: episodeTabModel.isDirectOpen
        ? EpisodeListState.All
        : model?.getEpisodeTabModel?.episodeListState ?? EpisodeListState.Open,
      selectedSubTab: selectedSubTab
        ? selectedSubTab
        : mapSubTabKeys(model?.getEpisodeTabModel?.selectedSubTab),
      episodeTypes: model?.getEpisodeTabModel?.episodeTypes as EpisodeType[],
      subTabs: model?.getEpisodeTabModel
        ?.subTabs as PatientDetailsSubTabDefinition[],
    });
    setRefetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const dataQuery =
    episodeType !== 'cases'
      ? useLazyGetEpisodeTabDataQuery
      : useLazyGetCasesTabDataQuery;

  const [getData, { data, isFetching }] = dataQuery();

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (refetch) {
      const request = getRequest(
        episodeTabModel,
        patientId,
        showAllTypes,
        showTatColumn,
        showCounterColumn,
        readonly,
        episodeType
      );

      getData({ request }, false);
      setRefetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, refetch]);

  useEffect(() => {
    setRefetch(true);
  }, [patientId]);

  const actualData =
    episodeType !== 'cases'
      ? (data as GetEpisodeTabDataQuery)?.getEpisodeTabData
      : (data as GetCasesTabDataQuery)?.getCasesTabData;

  const episodes = actualData?.episodes;

  useEffect(() => {
    if (episodeTabModel.isDirectOpen) {
      setSelectedEpisodeId(episodeTabModel.selectEpisode ?? 0);
      dispatch(setActiveEpisode(Number(episodeTabModel.selectEpisode)));
    } else {
      setSelectedEpisodeId(0);
      if (onEpisodeSelection) {
        onEpisodeSelection(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (limitGridHeight && handleEpisodesLengthChange) {
      handleEpisodesLengthChange(Number(episodes?.length) ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodes]);

  const oneCaseCanBeOpened: boolean =
    (episodes &&
      episodes.some(
        (x) => (x as CaseEpisodeViewModel)?.state === EpisodeState.Opened
      )) ??
    false;
  const lastCaseCanBeOpened: boolean =
    (episodes &&
      episodes.some(
        (x) => ((x as CaseEpisodeViewModel)?.id as number) > selectedEpisodeId
      )) ??
    false;

  const handleChangeSubTab = (activeTab: string) => {
    dispatch(removeJustAddedReminder());
    setTabModel({
      ...episodeTabModel,
      selectedSubTab: activeTab,
    });
  };

  const handleCloseEpisodeDetails = () => {
    dispatch(removeJustAddedReminder());
    setSelectedEpisodeId(0);
    dispatch(setActiveEpisode(0));
    if (episodeStateChanged) {
      setRefetch(true);
    }
    if (onEpisodeSelection) {
      onEpisodeSelection(0);
    }
  };

  const handleSetCurrentPage = (currentPage: number) => {
    setTabModel({
      ...episodeTabModel,
      page: currentPage,
      isDirectOpen: false,
      selectEpisode: undefined,
    } as IEpisodesTabState);
    setRefetch(true);
  };

  const getTabNameFilterState = () => {
    switch (episodeType) {
      case 'cases':
        return CASES_TAB_STATE_FILTER;
      case 'episodes':
        return EPISODES_TAB_STATE_FILTER;
      case 'orgDet':
        return ORG_DET_TAB_STATE_FILTER;
      case 'appealgriev':
        return APPEAL_GRIEVANCE_TAB_STATE_FILTER;
      default:
        return EPISODES_TAB_STATE_FILTER;
    }
  };

  const handleChangeEpisodeState = (filter: ILookupValue) => {
    setTabModel({
      ...episodeTabModel,
      episodeListState: String(filter.id),
      isDirectOpen: false,
      selectEpisode: undefined,
      page: 0,
    } as IEpisodesTabState);
    setUserPreference({
      name: getTabNameFilterState(),
      value: String(filter.id),
    });
    setRefetch(true);
  };

  const handleSetSorting = (sorting: Sorting[]) => {
    setTabModel({
      ...episodeTabModel,
      page: 0,
      orderBy: sorting[0].columnName,
      ascDesc: sorting[0].direction.toString(),
      isDirectOpen: false,
      selectEpisode: undefined,
    } as IEpisodesTabState);
    setRefetch(true);
  };

  const handleSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const newId = Number(values[1]);
      setSelectedEpisodeId(newId);
      dispatch(setActiveEpisode(newId));
      if (onEpisodeSelection) {
        const selectedEpisode =
          episodes &&
          (find(episodes, (i) => (i as Episode)?.id === newId) as Episode);
        onEpisodeSelection(newId, selectedEpisode?.episodeNumber ?? '');
      }
      dispatch(clearDocumentState());
      dispatch(resetMozartState());
    }
  };

  const selectedEpisodeHasTat =
    episodes?.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) => item?.id === selectedEpisodeId && item?.tat !== null
    ) ?? false;

  const selectedEpisodeHasCounter =
    episodes?.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) => item?.id === selectedEpisodeId && item?.counter !== null
    ) ?? false;

  const isEpisodeSelected = selectedEpisodeId !== 0 && !shouldNotOpenSidePanel;

  const getEpisodeColumnExtensions = () => {
    if (episodeColumns) {
      return moveFlowColumnsExtension;
    }
    return isEpisodeSelected
      ? getOpenedColumnsExtension(episodeType, showAllTypes)
      : getColumnExtensions(episodeType, showAllTypes ?? false);
  };

  const getEpisodeColumns = () => {
    if (episodeColumns) {
      return episodeColumns;
    }
    return isEpisodeSelected
      ? getOpenedColumns(episodeType, showAllTypes)
      : getColumns(episodeType, showAllTypes ?? false);
  };

  const tabRows = (episodes ?? []) as EpisodeWithMozartInfoViewModel[];

  const isLoading = isModelFetching || isFetching || isUpdatingUserPreference;

  const totalCount = Number(actualData?.pager?.totalCount ?? 0);

  const mozartWorkflow = tabRows.find((i) => i.id === selectedEpisodeId);

  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  const mozartWorkflowDetails = {
    workflowInstanceId: mozartWorkflow?.workFlowInstanceId,
    nextStep: mozartWorkflow?.nextStep,
    mozartWorkflowCompleted: mozartWorkflow?.mozartWorkflowCompleted,
    mozartActionEnabled: mozartWorkflow?.mozartActionEnabled,
  };

  const isFullWidth =
    selectedEpisodeId === 0 ||
    (shouldNotOpenSidePanel && selectedEpisodeId > 0);

  return (
    <div
      style={style}
      data-cy="episodes-tab-content"
      data-testid="episodes-tab"
    >
      <Grid
        container
        spacing={3}
        data-cy="episodes-grid"
        style={{ marginTop: 0 }}
      >
        <Grid
          item
          xs={isFullWidth ? 12 : 4}
          style={{
            borderRight: isFullWidth ? '0' : `1px solid ${COLORS.GREY25}`,
            paddingRight: isFullWidth ? 0 : '24px',
            pointerEvents: isInEditMode ? 'none' : 'auto',
          }}
        >
          {!shouldNotOpenSidePanel ? (
            <EpisodeTabHeader
              onChangeEpisodeState={handleChangeEpisodeState}
              episodeListState={episodeTabModel.episodeListState}
              title={episodeType === 'cases' ? 'Cases' : 'Episodes'}
            />
          ) : null}
          <EpisodeGrid
            tabColumns={getEpisodeColumns()}
            tabRows={tabRows}
            totalCount={totalCount}
            selectedEpisodeId={selectedEpisodeId}
            onSelectionChange={handleSelectionChange}
            episodeTabModel={episodeTabModel}
            onSetCurrentPage={handleSetCurrentPage}
            onSetSorting={handleSetSorting}
            columnExtensions={getEpisodeColumnExtensions()}
            limitGridHeight={limitGridHeight}
          />
        </Grid>
        {(selectedEpisodeId > 0 && shouldNotOpenSidePanel) ||
          (totalCount > 0 && selectedEpisodeId > 0 && (
            <Grid item xs={8} hidden={isFullWidth} style={{ paddingLeft: 0 }}>
              <SubTabs
                selectedSubTab={episodeTabModel.selectedSubTab}
                patientId={patientId}
                episodeId={selectedEpisodeId}
                editingChecklistId={editingChecklistId}
                onChangeSubTab={handleChangeSubTab}
                closeEpisodeDetails={handleCloseEpisodeDetails}
                hasTatTab={selectedEpisodeHasTat}
                hasCounterTab={selectedEpisodeHasCounter}
                episodeType={episodeType}
                readonly={readonlyGrid}
                oneCaseCanBeOpened={oneCaseCanBeOpened}
                lastCaseCanBeOpened={lastCaseCanBeOpened}
                setEpisodeStateChanged={setEpisodeStateChanged}
                setRefetch={setRefetch}
                subTabDefinitions={episodeTabModel.subTabs}
                mozartWorkflowDetails={mozartWorkflowDetails ?? {}}
                onEpisodeSelectionChange={handleSelectionChange}
              />
            </Grid>
          ))}
      </Grid>
      <Loader active={isLoading} />
    </div>
  );
};

export default EpisodesTab;
