import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { ChecklistCategory } from 'graphql/graphqlTypes';

import SectionNumber from '../sectionNumber';
import Icon, { ICONS } from '../../icon';
import styled from 'styled-components';
import { ChecklistStorageType } from 'store/actions/types';
import { GetHtmlId } from 'components/actions/navbar/navbar.helpers';
import { useSectionNumberStatus } from 'components/actions/sectionNumber/sectionNumber.hooks';
import { getStatus } from 'components/actions/sectionNumber/sectionNumber.helpers';
import { setActiveSectionIndex } from 'store/actions/checklistSlice';
import { useDispatch } from 'react-redux';

const StyledNavWrapper = styled.div`
  padding: 8px 24px;
  max-width: 253px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${COLORS.GREY10};
    cursor: pointer;
  }
`;

const StyledNavLink = styled(Typography)<{ $isActive: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.$isActive ? COLORS.BLACK : COLORS.GREY100)};
  padding-left: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) => (props.$isActive ? '500' : '400')};
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export type NavBarLinkStatus =
  | 'remaining'
  | 'required'
  | 'inProgress'
  | 'error'
  | 'done'
  | 'readOnly'
  | 'visited'
  | 'hover'
  | null;

export interface INavbarLinkProps {
  categoryIndex: number;
  category: ChecklistCategory;
  status?: NavBarLinkStatus;
  storageType: ChecklistStorageType;
}

const NavBarLink = (props: INavbarLinkProps) => {
  const [navLinkHovered, setNavLinkHovered] = useState<boolean>(false);
  const { categoryIndex, category, status, storageType } = props;
  const dispatch = useDispatch();
  const handleClick = () => {
    const categoryId = `#${GetHtmlId(category.name, category.id)}`;
    const section = document.querySelector(categoryId);
    dispatch(
      setActiveSectionIndex({
        storageType,
        activeSection: {
          id: categoryIndex + 1,
          categoryId: category.id,
        },
      })
    );
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const {
    isVisited,
    isInvalid,
    isReview,
    currentNavigationStep,
    showErrorBar,
  } = useSectionNumberStatus(category, category.id, storageType);

  const currentStatus = getStatus({
    currentNavigationStep,
    number: categoryIndex + 1,
    isVisited,
    isInvalid,
    isReadOnly: category.isReadOnly,
    status: status ?? null,
    showErrorBar,
    isReview,
    storageType,
  });

  return (
    <StyledNavWrapper
      key={categoryIndex}
      data-cy={`section-navigator-${categoryIndex}`}
      onMouseEnter={() => setNavLinkHovered(true)}
      onMouseLeave={() => setNavLinkHovered(false)}
      onClick={handleClick}
    >
      <SectionNumber
        number={categoryIndex + 1}
        hovered={navLinkHovered}
        categoryId={category.id}
        isReadOnly={category.isReadOnly}
        category={category}
        status={currentStatus}
        storageType={storageType}
      />
      <StyledWrapper>
        <StyledNavLink
          $isActive={currentStatus === 'inProgress'}
          variant="subtitle2"
        >
          {category.name}
        </StyledNavLink>
        {category.isReadOnly && (
          <Icon
            icon={ICONS.Lock}
            size={16}
            color={COLORS.GREY100}
            style={{ marginLeft: 'auto', marginBottom: '2px' }}
          />
        )}
      </StyledWrapper>
    </StyledNavWrapper>
  );
};

export default NavBarLink;
