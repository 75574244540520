import React, { useEffect, useState } from 'react';
import { IScenario } from 'backend/types/scenario';
import Panel from 'components/panel';
import { ISystemValue } from 'backend/types/systemValue';
import List from './List';
import { Box, Button, CircularProgress } from '@mui/material';
import { FAVORITE_SCENARIOS_ON_PROBLEMS_VIEW } from 'components/constants';
import { IAuthUser } from 'backend/types/authUser';
import { ClassNameMap } from '@mui/styles';
import Loader from 'components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { usePrevious } from 'hooks';
import { ROUTE } from 'consts/ROUTE';
import { useUpdateChecklistScenarioMutation } from 'graphql/hooks/updateChecklistScenario';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import { useCreateChecklistMutation } from 'graphql/hooks/createChecklist';
import { useStartMozartWorkflowMutation } from 'graphql/hooks/startMozartWorkflow';
import { startMozartWorkflow } from './scenarios.helpers';
import { useNavigate } from 'react-router';
import { useUnlockEpisodeMutation } from 'graphql/hooks/unlockEpisode';
import { useUpdateRemindersMutation } from 'graphql/hooks/updateEntitiesReminders';

export interface IScenariosProps {
  scenarios: IScenario[];
  selectedScenarioId: number | undefined;
  patientId: number | undefined;
  checklistId: number | null;
  episodeId: number | null;
  isNewEpisode: boolean | null;
  attachEpisodeId: number | null;
  systemValues: ISystemValue[];
  currentUser: IAuthUser;
  classes?: ClassNameMap<'title'>;
  onSelectScenario: (scenarioId: number) => void;
  scenariosLoading: boolean;
  updateSelectedScenario: (id: number) => void;
}

const Scenarios = (props: IScenariosProps) => {
  const {
    scenarios,
    selectedScenarioId,
    patientId,
    checklistId,
    episodeId,
    isNewEpisode,
    attachEpisodeId,
    systemValues,
    currentUser,
    onSelectScenario,
    scenariosLoading,
    updateSelectedScenario,
  } = props;
  const dispatch = useDispatch();
  const [workflowDefinitionId, setWorkflowDefinitionId] = useState<
    string | undefined
  >(undefined);
  const [startWorkflow, startStatus] = useStartMozartWorkflowMutation();

  const memberReminders = useSelector(
    (state: IState) => state.patientDetails.updatedReminders.memberReminders
  );
  const prevReminders = usePrevious(memberReminders);
  const navigate = useNavigate();

  const header =
    systemValues?.find((sv: ISystemValue) => sv.name === 'ProblemFormHeader1')
      ?.value || '';

  const [unlockEpisode, { isLoading: isUnlockEpisodeLoading }] =
    useUnlockEpisodeMutation();

  const onReturnHome = () => {
    unlockEpisode({
      episodeId: episodeId,
      checklistId: checklistId,
    }).then(() => {
      navigate(ROUTE.Home);
    });
  };

  const [setUserPreference, { isLoading: isUpdatingUserPreference }] =
    useSetPreferenceMutation();

  const [updatePatientReminders] = useUpdateRemindersMutation();

  const [
    createChecklist,
    {
      isLoading: isCreatingChecklist,
      data: createdChecklistResult,
      isSuccess: isSuccessCreated,
    },
  ] = useCreateChecklistMutation();

  const [
    updateChecklistScenario,
    {
      isLoading: isUpdatingChecklist,
      data: updatedChecklistResult,
      isSuccess: isSuccessUpdated,
    },
  ] = useUpdateChecklistScenarioMutation();

  useEffect(() => {
    if (
      isSuccessCreated &&
      createdChecklistResult &&
      createdChecklistResult.createChecklist &&
      createdChecklistResult.createChecklist.checklistId
    ) {
      const url = `/Checklist/Actions/${createdChecklistResult.createChecklist.checklistId}`;
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreated]);

  useEffect(() => {
    if (
      isSuccessUpdated &&
      updatedChecklistResult &&
      updatedChecklistResult.updateChecklistScenario &&
      updatedChecklistResult.updateChecklistScenario.checklistId
    ) {
      const url = `/Checklist/Actions/${updatedChecklistResult.updateChecklistScenario.checklistId}`;
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdated]);

  useEffect(() => {
    if (workflowDefinitionId && patientId && selectedScenarioId) {
      startWorkflow({
        id: workflowDefinitionId,
        patientId: patientId,
        scenarioId: selectedScenarioId,
        episodeId: episodeId ?? 0,
      }).then((result) => {
        startMozartWorkflow(
          result,
          dispatch,
          patientId,
          selectedScenarioId,
          workflowDefinitionId,
          navigate
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowDefinitionId]);

  const onSetFavoriteScenario = (ids: number[]) =>
    setUserPreference({
      name: FAVORITE_SCENARIOS_ON_PROBLEMS_VIEW,
      value: JSON.stringify(ids.map((x) => Number(x))),
    });

  const onSetSelectedScenario = (id: number) => onSelectScenario(id);
  const onNextStep = () => {
    const scenarioId = selectedScenarioId || 0;
    const definitionId = scenarios.find(
      (s) => s.id === scenarioId
    )?.workflowDefinitionId;
    setWorkflowDefinitionId(definitionId);

    if (memberReminders !== prevReminders && patientId) {
      const updateReminder = memberReminders || [];
      if (updateReminder.length) {
        updatePatientReminders({ entitiesReminders: updateReminder });
      }
    }

    if (checklistId) {
      updateChecklistScenario({
        data: {
          patientId: patientId ?? 0,
          scenarioId: scenarioId,
          problemIds: [],
          sessionId: currentUser.sessionId,
          checklistId: checklistId,
        },
      });
    } else if (!definitionId) {
      createChecklist({
        data: {
          patientId: patientId ?? 0,
          isNewEpisode: isNewEpisode,
          episodeId: episodeId ?? 0,
          attachEpisodeId: attachEpisodeId,
          scenarioId: scenarioId,
          problemIds: [],
          sessionId: currentUser.sessionId,
        },
      });
    }
  };

  return (
    <div>
      <Panel header={`Select ${header}`} isHighlighted>
        {scenariosLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            data-testid="scenarios-loader"
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <Box>
              <List
                selectedScenarioId={selectedScenarioId}
                scenarios={scenarios}
                onSetSelectedScenario={onSetSelectedScenario}
                onSetFavoriteScenario={onSetFavoriteScenario}
                updateSelectedScenario={updateSelectedScenario}
              />
            </Box>
            <div
              style={{ marginTop: 'auto', display: 'flex', paddingTop: '24px' }}
            >
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignSelf: 'flex-end',
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={() => onReturnHome()}
                  data-cy="return-home"
                  style={{ marginRight: '15px' }}
                >
                  Return Home
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={!selectedScenarioId}
                  onClick={onNextStep}
                  data-cy="next"
                >
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
      </Panel>
      <Loader
        active={
          isCreatingChecklist ||
          isUpdatingChecklist ||
          isUpdatingUserPreference ||
          startStatus.isLoading ||
          isUnlockEpisodeLoading
        }
      />
    </div>
  );
};

export default Scenarios;
