import React, { useState } from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { IconButton, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import Confirmation from 'components/modal/Confirmation';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const DeleteByIdFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  if (props.value.inCarePlan || props.value.isReadOnly) {
    return null;
  }

  return (
    <>
      <CustomTooltip title="Delete Problem">
        <div>
          <IconButton
            data-testid="delete-problem-icon"
            onClick={() => setShowDeleteConfirmation(true)}
            size="large"
          >
            <Icon size={20} icon={ICONS.Remove} />
          </IconButton>
        </div>
      </CustomTooltip>
      <Confirmation
        open={showDeleteConfirmation}
        title={'Delete problem?'}
        okButtonText="Delete"
        okEvent={() => {
          setShowDeleteConfirmation(false);
          props.value.deleteProblem(props.value.id);
        }}
        cancelEvent={() => setShowDeleteConfirmation(false)}
      >
        <Typography>{`Are you sure you want to delete this problem?`}</Typography>
      </Confirmation>
    </>
  );
};

const DeleteByIdProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={DeleteByIdFormatter} {...props} />
);

export default DeleteByIdProvider;
