import React, { forwardRef } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PrintPreviewWrapper } from 'styles/printStyles';
import { useSelector } from 'react-redux';
import ChecklistPreviewWrapper from 'components/checklistViewPrint/ChecklistPreviewWrapper';
import { IState } from 'store';

const StyledContent = styled.div`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 5px;
`;
const StyledContainer = styled.div`
  padding: 0 30px;
  min-height: 40vh;
`;

interface IChecklistPrintLayoutProps {
  isPrintOpen: boolean;
  storageType: ChecklistStorageType;
  patientId?: number;
}

const ChecklistPrintLayout = forwardRef<
  HTMLDivElement,
  IChecklistPrintLayoutProps
>(({ isPrintOpen, storageType, patientId }, ref) => {
  const selectedChecklistIds = useSelector(
    (state: IState) => state.ui.print.documents.nonMozart.selectedChecklistIds
  );

  return (
    <div ref={ref}>
      {isPrintOpen && (
        <PrintPreviewWrapper>
          <StyledContent>
            <StyledContainer>
              <ChecklistPreviewWrapper
                checklistIds={selectedChecklistIds ?? []}
                patientId={patientId ?? 0}
                storageType={storageType}
              />
            </StyledContainer>
          </StyledContent>
        </PrintPreviewWrapper>
      )}
    </div>
  );
});
ChecklistPrintLayout.displayName = 'ChecklistPrintLayout';

export default ChecklistPrintLayout;
