import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, styled, debounce } from '@mui/material';
import { useTimer } from 'react-timer-hook';
import { v4 as uuid } from 'uuid';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import {
  AssociatedClaimActionValue,
  ChecklistItem,
  ChecklistItemAssociatedClaims,
  Maybe,
  MedicationCode,
  PatientClaimDto,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import {
  useLazySearchClaimsQuery,
  useSearchClaimsQuery,
} from 'graphql/hooks/searchClaims';
import {
  validateMinAmountValues,
  validateRequired,
} from 'util/validationUtils';
import { COLORS } from 'consts/styles';
import { INDIVIDUAL_COMPONENT_CODE } from 'components/constants';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import ClaimSearchDialog from 'components/claimSearchDialog/ClaimSearchDialog';
import ClaimSearch from './ClaimSearch';
import AssociatedClaimTable from './AssociatedClaimTable';
import {
  AssociatedClaimAction,
  defaultClaimActionValue,
  isClaimIdAlreadyExists,
  mapAssociatedClaim,
  transformMedicationCodes,
} from './AssociatedClaim.helper';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';

export const ClaimBox = styled(Box)({
  display: 'flex',
  paddingTop: '12px',
  paddingBottom: '12px',
});

export const ErrorText = styled(Typography)({
  color: COLORS.RED100,
});

export interface IAssociatedClaimProps {
  item: ChecklistItemAssociatedClaims;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  isReadOnly: boolean;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
}

const AssociatedClaim = (props: IAssociatedClaimProps) => {
  const {
    item,
    categoryId,
    autoSave,
    orderableIndex,
    storageType,
    isReadOnly,
    updateChecklistItemInputValueOnSave,
  } = props;
  const { minRequired, maxAllowed, associatedClaimAttributes } = item.options;
  const itemOrItems = maxAllowed > 1 ? 'items' : 'item';
  const addClaimButtonTooltipText = `Maximum ${maxAllowed} ${itemOrItems} can be added`;
  const dispatch = useDispatch();
  const patientId = Number(
    useSelector(
      (state: IState) => state.checklist.documentsState[storageType].patientId
    ) ?? 0
  );
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const [selectedClaims, setSelectedClaims] = useState<AssociatedClaimAction[]>(
    mapAssociatedClaim(item.acValue)
  );
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const { updateActionValueExtended } = useUpdateActionValue();
  const { data: allClaimData, isFetching: isAllClaimLoading } =
    useSearchClaimsQuery(
      { request: { patientId } },
      { skip: patientId === 0, refetchOnMountOrArgChange: true }
    );
  const [
    searchClaims,
    {
      data: searchClaimData,
      isFetching: isSearchClaimLoading,
      isSuccess: isSearchClaimSuccess,
    },
  ] = useLazySearchClaimsQuery();
  const useDefaultClaims =
    searchTerm.length < 3 || isSearchClaimLoading || !isSearchClaimSuccess;
  const claims = (
    useDefaultClaims
      ? allClaimData?.searchClaims.rows
      : searchClaimData?.searchClaims.rows
  ) as PatientClaimDto[];
  const filteredClaims = claims?.filter(
    (x) => !selectedClaims.map((y) => y.id).includes(x.id)
  );

  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      searchClaims({
        request: {
          searchTerm,
          patientId,
        },
      });
    },
  });

  useEffect(() => {
    if (searchTerm.length < 3) {
      return;
    }
    const now = new Date();
    restart(new Date(now.getTime() + 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, patientId]);

  useEffect(() => {
    const claimActionValues = selectedClaims.map((x) => {
      return {
        id: x.id,
        claimId: x.claimId,
        claimDate: x.claimDate,
        serviceFrom: x.serviceFrom,
        serviceTo: x.serviceTo,
        providerId: x.provider?.id ?? null,
        paidAmount: x.paidAmount,
        diagnosisCodes:
          typeof x.diagnosisCodes === 'string'
            ? x.diagnosisCodes
            : transformMedicationCodes(
                x.diagnosisCodes?.filter(
                  (code): code is MedicationCode => !!code
                ) ?? null
              ),
        procedureCodes:
          typeof x.procedureCodes === 'string'
            ? x.procedureCodes
            : transformMedicationCodes(
                x.procedureCodes?.filter(
                  (code): code is MedicationCode => !!code
                ) ?? null
              ),
      };
    });
    handleValidation(claimActionValues);
    handleInputChange(claimActionValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClaims]);

  const handleClaimSelection = (value: AssociatedClaimAction) => {
    const result = isClaimIdAlreadyExists(selectedClaims, value);
    if (result.hasError && result.message) {
      setErrorMessage(result.message);
      return;
    }
    const associatedClaim = { ...value, uniqueId: uuid() };
    setSelectedClaims([...selectedClaims, associatedClaim]);
    setSearchTerm('');
    setErrorMessage('');
    setShowSearchBar(false);
  };

  const handleDeleteClaim = (associatedClaim: AssociatedClaimAction) => {
    const associatedClaims = selectedClaims.filter(
      (x) => x.uniqueId !== associatedClaim.uniqueId
    );
    setSelectedClaims(associatedClaims);
  };

  const handleChangeClaim = (associatedClaim: AssociatedClaimAction) => {
    const associatedClaims = selectedClaims.map((x) =>
      x.uniqueId === associatedClaim.uniqueId ? associatedClaim : x
    );
    setSelectedClaims(associatedClaims);
  };

  const handleViewAllClaim = () => {
    setErrorMessage('');
    setAdvancedSearchOpen(true);
  };

  const handleCantFindClaim = () => {
    setSelectedClaims([...selectedClaims, defaultClaimActionValue()]);
    setShowSearchBar(false);
  };

  const handleInputChange = debounce(
    (claimActionValues: AssociatedClaimActionValue[]) => {
      if (!checklistId) {
        return;
      }
      const data = {
        checklistId: Number(checklistId),
        componentId: INDIVIDUAL_COMPONENT_CODE,
        id: item.uid,
        type: 'string',
        value: JSON.stringify(claimActionValues),
      };
      if (autoSave) {
        updateActionValueExtended(data, item.uid);
      } else {
        updateChecklistItemInputValueOnSave(item, orderableIndex, data);
      }
    },
    100
  );

  const handleValidation = (newClaims: Maybe<AssociatedClaimActionValue>[]) => {
    validateMinimumNoOfClaim(newClaims.length);
    validateClaimId(newClaims);
  };

  const validateMinimumNoOfClaim = (noOfClaims: number) => {
    const result = validateMinAmountValues(noOfClaims, minRequired);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: item.uid,
          error: result.message ?? '',
          fieldName: 'Associated Claims',
          categoryId: categoryId,
          isValid: !result.message,
        },
      })
    );
  };

  const validateClaimId = (newClaims: Maybe<AssociatedClaimActionValue>[]) => {
    const errors = newClaims
      ?.map((x) => validateRequired(x?.claimId, true))
      ?.filter((x) => x.hasError);

    errors?.forEach((x) => {
      dispatch(
        setChecklistComponentValidation({
          storageType,
          error: {
            uuid: item.uid,
            error: x.message ?? '',
            fieldName: 'Associated Claims : Claim ID',
            categoryId: categoryId,
            isValid: !x.message,
          },
        })
      );
    });
  };

  return (
    <>
      <Box id={`uuid-${item.uid}`} pl="64px" pr="64px">
        {selectedClaims.length > 0 && (
          <AssociatedClaimTable
            claimAttributes={associatedClaimAttributes}
            claimActionValues={selectedClaims}
            onDelete={handleDeleteClaim}
            onChange={handleChangeClaim}
          />
        )}
        {showSearchBar ? (
          <>
            {errorMessage && (
              <Box>
                <ErrorText>{errorMessage}</ErrorText>
              </Box>
            )}
            <Box pt="14px">
              <Typography>Use the search box below to add items</Typography>
            </Box>
            <ClaimBox>
              <ClaimSearch
                claims={filteredClaims}
                searchTerm={searchTerm}
                isReadOnly={isReadOnly}
                isLoading={isAllClaimLoading || isSearchClaimLoading}
                onSelect={handleClaimSelection}
                onSearch={setSearchTerm}
                onViewAllClick={handleViewAllClaim}
                onCantFindClick={handleCantFindClaim}
              />
              <Button
                color="primary"
                variant="text"
                onClick={() => {
                  setErrorMessage('');
                  setAdvancedSearchOpen(true);
                }}
                disabled={isReadOnly}
              >
                Advanced Search
              </Button>
            </ClaimBox>
          </>
        ) : (
          <Box display="flex" pt="14px" pb="14px">
            <CustomTooltip title={addClaimButtonTooltipText} noMaxWidth>
              <Button
                variant="text"
                color="primary"
                size="small"
                disabled={selectedClaims.length >= maxAllowed || isReadOnly}
                onClick={() => {
                  setShowSearchBar(true);
                }}
              >
                <Typography variant="body2">+ Add Claim Item</Typography>
              </Button>
            </CustomTooltip>
          </Box>
        )}
      </Box>
      <ClaimSearchDialog
        patientId={patientId}
        open={advancedSearchOpen}
        onSelect={handleClaimSelection}
        onClose={() => {
          setAdvancedSearchOpen(false);
        }}
        onCantFind={handleCantFindClaim}
      />
    </>
  );
};
export default AssociatedClaim;
