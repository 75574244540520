import React, { forwardRef } from 'react';
import { ILookupValue } from 'graphql/graphqlTypes';
import { MenuItem, TextField } from '@mui/material';
import { COLORS } from 'consts/styles';

interface ISelectTextFieldProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  menuItems?: ILookupValue[];
  value?: number | null;
  width?: string;
  readOnly?: boolean;
  error?: boolean;
  hideEmptySelect?: boolean;
}

const SelectTextField = forwardRef<
  HTMLInputElement | null,
  ISelectTextFieldProps
>((props, ref) => (
  <TextField
    select
    size="medium"
    disabled={props.readOnly ?? false}
    value={props?.value ?? ''}
    inputRef={ref}
    onChange={props.onChange}
    style={{ backgroundColor: COLORS.WHITE, width: props.width ?? '80px' }}
    variant="outlined"
    error={props.error}
    SelectProps={{
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
    }}
  >
    {!props.hideEmptySelect && (
      <MenuItem key="" value="">
        &nbsp;
      </MenuItem>
    )}
    {props.menuItems?.map((item) => (
      <MenuItem key={item.id} value={Number(item.id)}>
        {item.name}
      </MenuItem>
    ))}
  </TextField>
));

SelectTextField.displayName = 'SelectTextField';

export default SelectTextField;
