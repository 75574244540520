import {
  CarePlanState,
  ChecklistItem,
  ChecklistItemCarePlans,
  ChecklistProblemViewModel,
  ChecklistViewModel,
} from 'graphql/graphqlTypes';

export enum problemsColumnNames {
  name = 'name',
  inCarePlan = 'inCarePlan',
  comment = 'note',
  status = 'status',
  id = 'id',
}

export const problemsColumnsExtensionStyles = [
  {
    columnName: problemsColumnNames.name,
    width: '20%',
  },
  {
    columnName: problemsColumnNames.comment,
  },
  {
    columnName: problemsColumnNames.status,
    width: '8%',
  },
  {
    columnName: problemsColumnNames.inCarePlan,
    width: '14%',
  },
  {
    columnName: problemsColumnNames.id,
    width: '5%',
  },
];

export const addProblemsColumnsExtensionStyles = [
  {
    columnName: problemsColumnNames.name,
  },
];

export const itemsWithProblem = (problemId: number) => (item: ChecklistItem) =>
  item?.orderSet?.problems?.some((problem) => problem.id === problemId);

export const getCarePlanStatus = (
  checklist: ChecklistViewModel | null,
  checklistProblem: ChecklistProblemViewModel
) => {
  if (!checklistProblem.inCarePlan) {
    return 'New';
  }
  if (checklist) {
    const problemId = Number(checklistProblem.id);
    const problemCategoriesChecklist: ChecklistViewModel = {
      ...checklist,
      categories:
        checklist?.categories.map((category) => ({
          ...category,
          items: category.items.filter(itemsWithProblem(problemId)),
        })) ?? [],
    };
    const checklistItemCarePlans = problemCategoriesChecklist.categories
      ?.flatMap((category) =>
        category?.items.filter((x) => x.type === 'care plan')
      )
      ?.map((item: ChecklistItem) => item as ChecklistItemCarePlans);
    if (checklistItemCarePlans.length > 0) {
      const carePlanState = checklistItemCarePlans.at(0)?.cpValue.carePlanState;
      if (carePlanState === CarePlanState.Opened) {
        return 'Open';
      } else if (carePlanState === CarePlanState.Closed) {
        return 'Closed';
      }
    }
    return 'N/A';
  }
  return 'New';
};
