import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type StartMozartWorkflowMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  patientId: Types.Scalars['Int'];
  scenarioId: Types.Scalars['Int'];
  episodeId: Types.Scalars['Int'];
}>;

export type StartMozartWorkflowMutation = {
  __typename?: 'Mutation';
  startMozartWorkflow?: {
    __typename?: 'MozartStartWorkflowResult';
    workflowInstanceId: any;
    episodeId: number;
    pollingTimeout: number;
    errors?: Array<string | null> | null;
    userInput: boolean;
    selectedScenario?: {
      __typename?: 'Scenario';
      id: number;
      workflowDefinitionId?: any | null;
      name?: string | null;
      displayName?: string | null;
      isActive?: boolean | null;
      index?: number | null;
      favorite?: boolean | null;
      createdOn?: any | null;
      createdBy: number;
      updatedBy?: number | null;
      updatedOn?: any | null;
    } | null;
  } | null;
};

export const StartMozartWorkflowDocument = `
    mutation startMozartWorkflow($id: UUID!, $patientId: Int!, $scenarioId: Int!, $episodeId: Int!) {
  startMozartWorkflow(
    request: {episodeId: $episodeId, id: $id, patientId: $patientId, scenarioId: $scenarioId}
  ) {
    workflowInstanceId
    episodeId
    pollingTimeout
    selectedScenario {
      id
      workflowDefinitionId
      name
      displayName
      isActive
      index
      favorite
      createdOn
      createdBy
      updatedBy
      updatedOn
    }
    errors
    userInput
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    startMozartWorkflow: build.mutation<
      StartMozartWorkflowMutation,
      StartMozartWorkflowMutationVariables
    >({
      query: (variables) => ({
        document: StartMozartWorkflowDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useStartMozartWorkflowMutation } = injectedRtkApi;
