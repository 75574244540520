import React from 'react';
import styled from 'styled-components';
import { Button, Divider, Typography, Chip } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { updateFilters } from 'store/home/action';
import { IconFallBack } from 'components/IconFallBack';
import {
  appliedFiltersSelector,
  formatMultiValue,
} from './GridFilters.helpers';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IGridFiltersProps {
  handleSetLoadingComplete: (loadingComplete: boolean) => void;
}

const StyledTypography = styled(Typography)`
  padding-right: 16px;
`;

const StyledFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -6px;
  margin-bottom: 14px;
`;

const StyledDivider = styled(Divider)`
  margin: 0 4px 0 4px;
  background-color: ${COLORS.GREY25};
  width: 1px;
  height: 20px;
`;

const StyledChip = styled(Chip)`
  margin-right: 12px;
  max-width: 300px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const StyledChipText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  color: ${COLORS.GREY100};
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding-left: 4px;
`;

const GridFilters = ({ handleSetLoadingComplete }: IGridFiltersProps) => {
  const appliedFilters = useSelector((state: IState) =>
    appliedFiltersSelector(state)
  );
  const dispatch = useDispatch();
  const originalAppliedFilters = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  );
  const handleDeleteFilter = (
    filterValue?: string,
    originalValue?: string,
    isMultiValue?: boolean,
    columnName?: string
  ) => {
    if (isMultiValue) {
      const updatedFilters = originalAppliedFilters
        ?.map((i) =>
          i.value === originalValue
            ? {
                ...i,
                value: formatMultiValue(i.value, filterValue),
              }
            : i
        )
        .filter((i) => i.value !== '');

      dispatch(updateFilters(updatedFilters ? updatedFilters : []));
      handleSetLoadingComplete(true);
      return;
    }
    handleSetLoadingComplete(true);
    if (originalAppliedFilters) {
      dispatch(
        updateFilters(
          originalAppliedFilters?.filter(
            (filter) =>
              !(filter.columnName == columnName && filter.value == filterValue)
          )
        )
      );
    }
  };

  const handleClearAll = () => {
    handleSetLoadingComplete(true);
    dispatch(updateFilters([]));
  };

  const chipTitle = (label: string, text: string, image?: string | null) => {
    return (
      <StyledWrapper>
        <CustomTooltip title={`${label} : ${text}`}>
          <StyledChipText>
            {label} : {image ? '' : text}
          </StyledChipText>
        </CustomTooltip>

        {image ? (
          <IconWrapper>
            <IconFallBack name={text} image={image} type="Tag" iconSize={16} />
          </IconWrapper>
        ) : null}
      </StyledWrapper>
    );
  };

  return (
    <StyledFiltersContainer
      data-testid="home-grid-filter-bar"
      data-cy="home-grid-filter-bar"
    >
      <StyledTypography variant="body2">Filters:</StyledTypography>
      {appliedFilters &&
        appliedFilters?.map((filter) => (
          <StyledChip
            label={chipTitle(
              filter?.name ?? '',
              filter?.displayValue ?? '',
              filter?.image
            )}
            key={filter?.value}
            color="primary"
            variant="outlined"
            onDelete={() =>
              handleDeleteFilter(
                filter?.value,
                filter?.originalValue,
                filter?.isMultiValue,
                filter?.columnName
              )
            }
          />
        ))}
      <StyledDivider />
      <StyledButton variant="text" size="small" onClick={handleClearAll}>
        Clear all
      </StyledButton>
    </StyledFiltersContainer>
  );
};

export default GridFilters;
