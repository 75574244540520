import { PatientClaimDto, SearchClaimRequestInput } from 'graphql/graphqlTypes';
import {
  formatPaidAmount,
  getMedicationCodesWithDescription,
  getProviderDetails,
} from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';
import { IClaimAdditionalFilters } from './types';
import { Sorting } from '@devexpress/dx-react-grid';
import { formatValidDate } from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';

export const columns = [
  { name: 'claimId', title: 'Claim ID' },
  { name: 'claimDate', title: 'Claim Date' },
  { name: 'serviceFrom', title: 'Service From' },
  { name: 'serviceTo', title: 'Service To' },
  {
    name: 'diagnosisCodes',
    title: 'Diagnosis',
    getCellValue: (row: PatientClaimDto) =>
      getMedicationCodesWithDescription(row?.diagnosisCodes),
  },
  {
    name: 'procedureCodes',
    title: 'Procedure',
    getCellValue: (row: PatientClaimDto) =>
      getMedicationCodesWithDescription(row?.procedureCodes),
  },
  {
    name: 'provider',
    title: 'Provider Name',
    getCellValue: (row: PatientClaimDto) => getProviderDetails(row?.provider),
  },
  {
    name: 'paidAmount',
    title: 'Paid Amount',
    getCellValue: (row: PatientClaimDto) => formatPaidAmount(row?.paidAmount),
  },
];

export const columnsExtension = [
  { columnName: 'claimId', sortingEnabled: true },
  { columnName: 'claimDate', sortingEnabled: true },
  { columnName: 'serviceFrom', sortingEnabled: true },
  { columnName: 'serviceTo', sortingEnabled: true },
  { columnName: 'diagnosis', sortingEnabled: true },
  { columnName: 'medProcedure', sortingEnabled: true },
  { columnName: 'provider', sortingEnabled: true },
  { columnName: 'paidAmount', sortingEnabled: true },
];

export const getDefaultClaimAdditionalFilter = (): IClaimAdditionalFilters => {
  return {
    diagnosisCode: null,
    procedureCode: null,
    claimDateStart: null,
    claimDateEnd: null,
  };
};

export const toSearchClaimRequestInput = (
  patientId: number,
  searchTerm: string,
  filters: IClaimAdditionalFilters,
  pageNumber?: number,
  sorting?: Sorting
): SearchClaimRequestInput => {
  const filter = {
    ...filters,
    claimDateStart: formatValidDate(filters.claimDateStart),
    claimDateEnd: formatValidDate(filters.claimDateEnd),
  };
  return {
    patientId,
    searchTerm,
    pageNumber: pageNumber ?? 0,
    pageSize: 15,
    orderBy: sorting?.columnName ?? 'ClaimDate',
    ascDesc: sorting?.direction.toString() ?? 'desc',
    ...filter,
  };
};

export const validateDate = (date: Date | null) => {
  if (!date) {
    return true;
  }
  return date.toDateString() !== 'Invalid Date';
};
