import { UseFormSetValue, useForm } from 'react-hook-form';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import {
  EpisodeBalancerCriteria,
  EpisodeBalancerCriteriaInput,
  User,
} from 'graphql/graphqlTypes';
import { formConstants } from 'components/constants';
import { toLookupValue } from 'util/lookupHelper';
import { EpisodeDistributeForm } from './types';
import { IRole } from 'store/roles/types';
import { uniqBy } from 'lodash';

export const toAddEpisodeBalancerCriteriaMutationVariables = (
  source: EpisodeDistributeForm
): EpisodeBalancerCriteriaInput => {
  return {
    userIds: source.users.map((x) => x.id),
    totalEpisodesOwned: Number(source.totalEpisodesOwned),
    careSiteIds: source.careSites.map((x) => x.id),
    careStageIds: source.careStages.map((x) => x.id),
    pathwayIds: source.pathways.map((x) => x.id),
    serviceLocationIds: source.serviceLocations.map((x) => x.id),
  };
};

export const toUserLookupValues = (
  users: User[],
  skipNameSort?: boolean
): ILookupValue[] => {
  const lookupValues =
    users.map((x) => {
      return { id: x.id, name: x.name };
    }) ?? [];
  return mapDataToLookupValues(lookupValues, skipNameSort);
};
export const toCareSiteLookupValues = (
  roles: IRole[],
  skipNameSort?: boolean
): ILookupValue[] => {
  if (roles.length > 0) {
    const careSitesFilter = roles
      .filter((r) => r.careSiteId)
      .map((item) => ({
        name: item.careSiteName,
        id: item.careSiteId,
      }));

    return mapDataToLookupValues(uniqBy(careSitesFilter, 'id'), skipNameSort);
  }
  return [];
};
export const toLookupValues = (
  lookups: ILookup[],
  lookupName: string,
  skipNameSort?: boolean
): ILookupValue[] => {
  const lookupValues =
    lookups
      .find((x) => x.name == lookupName)
      ?.values?.map((x) => {
        return { id: x.id, name: x.name };
      }) ?? [];
  return mapDataToLookupValues(lookupValues, skipNameSort);
};

export const mapDataToLookupValues = (
  data: { id: number; name?: string | null }[],
  skipNameSort?: boolean
) => {
  return data
    .map((item) => {
      const newItem: ILookupValue = {
        ...item,
        id: item.id,
        name: item.name ?? '',
      };
      return newItem;
    })
    .sort((a, b) => {
      if (!skipNameSort) {
        const comparison = a.name.localeCompare(b.name);
        if (comparison !== 0) {
          return comparison;
        }
      }
      return a.id - b.id;
    });
};

export const toEpisodeDistributeFormDefaultValues = (
  careSites?: ILookupValue[],
  users?: ILookupValue[],
  totalEpisodesOwned?: number | string,
  careStages?: ILookupValue[],
  pathways?: ILookupValue[],
  serviceLocations?: ILookupValue[]
) => {
  return {
    users: users ?? [],
    totalEpisodesOwned: totalEpisodesOwned ?? '',
    careSites: careSites ?? [],
    careStages: careStages ?? [],
    pathways: pathways ?? [],
    serviceLocations: serviceLocations ?? [],
  } as EpisodeDistributeForm;
};

export const resetToDefaultEpisodeDistributeDetails = (
  episodeBalancerCriteria: EpisodeBalancerCriteria | null | undefined,
  setValue: UseFormSetValue<EpisodeDistributeForm>,
  defaultValues?: EpisodeDistributeForm
) => {
  setValue(
    'users',
    episodeBalancerCriteria?.users?.map((x) => toLookupValue(x)) ?? []
  );
  setValue(
    'totalEpisodesOwned',
    episodeBalancerCriteria?.totalEpisodesOwned ?? 0
  );
  setValue(
    'careSites',
    (episodeBalancerCriteria?.careSites?.length ?? 0) > 0
      ? episodeBalancerCriteria?.careSites?.map((x) => toLookupValue(x)) ?? []
      : defaultValues?.careSites ?? []
  );
  setValue(
    'careStages',
    episodeBalancerCriteria?.careStages?.map((x) => toLookupValue(x)) ?? []
  );
  setValue(
    'pathways',
    episodeBalancerCriteria?.pathways?.map((x) => toLookupValue(x)) ?? []
  );
  setValue(
    'serviceLocations',
    episodeBalancerCriteria?.serviceLocations?.map((x) => toLookupValue(x)) ??
      []
  );
};

export const useEpisodeDistributeForm = () => {
  return useForm<EpisodeDistributeForm>({
    defaultValues: {
      users: [],
      totalEpisodesOwned: '',
      careSites: [],
      careStages: [],
      pathways: [],
      serviceLocations: [],
    },
    mode: formConstants.defaultMode,
    reValidateMode: formConstants.defaultReValidateMode,
  });
};
