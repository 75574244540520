import { useSelector } from 'react-redux';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';

export const useChecklistForSidebar = (storageType: ChecklistStorageType) => {
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const isPrintOpen = useSelector(
    (state: IState) => state.ui.print.documents.isPrintOpen
  );
  const checklistForNonMozart =
    useSelector(
      (state: IState) => state.ui.print.documents.nonMozart.checklistViewModel
    ) ?? [];
  const completedChecklist = Object.values(completedChecklists)[0];
  const fallbackChecklist = checklist ?? completedChecklist;
  const finalChecklist = isPrintOpen
    ? completedChecklist || checklistForNonMozart[0]
    : checklist || fallbackChecklist;
  return {
    checklist: finalChecklist,
  };
};
