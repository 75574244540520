import * as React from 'react';
import { TableCell } from '@mui/material';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { DATETIME_FORMAT, MOMENT_TIME_FORMAT } from 'components/constants';
import DateComponent from 'components/DateComponent';
import TimeComponent from 'components/TimeComponent';
import styled from 'styled-components';
import {
  formatDateTime,
  getUtcTimeFromCareSiteTime,
} from 'util/helpers/dateTimeHelpers';

const StyledDiv = styled.div`
  display: flex;
`;

const StyledWrapper = styled.div`
  margin-right: 8px;
`;

export const DateTimeEditor = (
  props: TableEditRow.CellProps & { autoFocus?: boolean }
) => {
  const { value, onValueChange, autoFocus } = props;

  const handleDateTimeChange = (newDate: Date | null | string | undefined) => {
    const newDateTime = newDate as Date;
    if (newDateTime) {
      const utcDateTime = getUtcTimeFromCareSiteTime(newDateTime);
      onValueChange(utcDateTime.toISOString());
    }
  };

  const getDateTime = (value: string): Date => {
    return new Date(formatDateTime(value, DATETIME_FORMAT));
  };

  return (
    <TableCell style={CellComponentStyle}>
      <StyledDiv>
        <StyledWrapper>
          <DateComponent
            value={getDateTime(value.date)}
            testId="date-editor"
            onChange={handleDateTimeChange}
            required={true}
            autoFocus={autoFocus}
          />
        </StyledWrapper>
        <TimeComponent
          value={getDateTime(value.date)}
          testId="time-editor"
          onChange={handleDateTimeChange}
          timeFormat={MOMENT_TIME_FORMAT}
          autoFocus={autoFocus}
          required={false}
        />
      </StyledDiv>
    </TableCell>
  );
};
