import * as React from 'react';
import { TableCell } from '@mui/material';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import DateComponent from 'components/DateComponent';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { DATETIME_FORMAT } from 'components/constants';
import { handleDateTimeChange } from './dateEditor.helper';
import moment from 'moment';

export const DateEditor = (
  props: TableEditRow.CellProps & { autoFocus?: boolean }
) => {
  const { value, onValueChange, autoFocus } = props;
  const isDueDateTimeUtc = props.row.isDueDateTimeUtc;
  const handleDateChange = (newDate: Date | null | unknown) => {
    handleDateTimeChange(newDate, onValueChange);
  };

  const getDate = (value: string): Date => {
    if (!isDueDateTimeUtc) {
      return moment(value).toDate();
    }

    return new Date(formatDateTime(value, DATETIME_FORMAT));
  };

  return (
    <TableCell style={CellComponentStyle}>
      <DateComponent
        value={getDate(value.date)}
        testId="date-editor"
        onChange={handleDateChange}
        required={true}
        autoFocus={autoFocus}
      />
    </TableCell>
  );
};
